import { DropdownInfo } from "../../../models/types";

const emptyDropdown: DropdownInfo = {
    id: 0,
    description: "",
};

export const emptyDestinationAB: destinationABInterface = {
    id: 0,
    name: "",
    cameraPointA: emptyDropdown,
    cameraPointB: emptyDropdown,
    locationPointA: emptyDropdown,
    locationPointB: emptyDropdown,
    isActive: false,
    userId: 0,
    creationDateTime: new Date(),
};

export interface destinationABInterface {
    id: number;
    name: string;
    cameraPointA: DropdownInfo;
    cameraPointB: DropdownInfo;
    locationPointA: DropdownInfo;
    locationPointB: DropdownInfo;
    isActive: boolean;
    userId: number;
    creationDateTime: Date;
}

export interface EventABRequest {
    cameraIdPointA: number;
    cameraIdPointB: number;
    locationIdPointA: number;
    locationIdPointB: number;
    vehiclePlate: string;
}
