import classNames from "classnames";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { DropdownInfo } from "../../../../models/types";
import { AuthServices } from "../../../../services/utils/auth-services";
import { CameraServices } from "../../../../services/camera-services";
import { LocationServices } from "../../../../services/location-services";
import { destinationABInterface } from "../../types/types";
import "./../../styles/destination-ab.scss";
import { FeatureServices } from "../../../../services/feature-services";
import { InputText } from "primereact/inputtext";
import { onInputChange } from "../../../../services/utils/input-services";

export interface CreateDestinationABDialogProps {
    destinationAB: destinationABInterface;
    setDestinationAB: (destinationAB: destinationABInterface) => void;
    setDestinationABDialog: (showDestinationABDialog: boolean) => void;
    isEditingDestinationAB: boolean;
    visible: boolean;
    submitted: boolean;
    setSubmitted: (value: boolean) => void;
    onSubmit: () => void;
    hideDialog: () => void;
    dialogHeader: string;
    setLoading: (isLoading: boolean) => void;
}

export const CreateDestinationABDialog = (props: CreateDestinationABDialogProps) => {
    const [camerasPointA, setCamerasPointA] = useState<DropdownInfo[]>([]);
    const [camerasPointB, setCamerasPointB] = useState<DropdownInfo[]>([]);
    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [loadingLocations, setLoadingLocations] = useState<boolean>(true);
    const [loadingCameras, setLoadingCameras] = useState<boolean>(false);

    const _cameraServices = new CameraServices();
    const _featureServices = new FeatureServices();
    const _authServices = new AuthServices();

    const [isNameValid, setIsNameValid] = useState(false);

    _authServices.HandleAppAuth();

    useEffect(() => {
        getLocations();
    }, [])

    const getLocations = () => {
        setLoadingLocations(true);
        _featureServices.getLocationsByFeature('DESTINATION-AB')
            .then(data => {
                var response: DropdownInfo[] = data.data;
                setLocations(response);
                setLoadingLocations(false);
                if (response.at(0)) {
                    var responseAt = response.at(0).id;
                    onSelectedLocationPointA(responseAt);
                    onSelectedLocationPointB(responseAt);
                }
            })
    }

    const onSelectedLocationPointA = (locationId: number) => {
        setLoadingCameras(true);

        let response: DropdownInfo[] = _cameraServices.getCamerasByLocation(locationId);

        removeAllCamerasOptionFromDropdown(response);
        setCamerasPointA(response)
        setLoadingCameras(false);
    }

    const onSelectedLocationPointB = (locationId: number) => {
        setLoadingCameras(true);

        let response: DropdownInfo[] = _cameraServices.getCamerasByLocation(locationId);

        removeAllCamerasOptionFromDropdown(response);

        setCamerasPointB(response)

        setLoadingCameras(false);
    }

    const removeAllCamerasOptionFromDropdown = (dropdown: DropdownInfo[]) => {
        const index = dropdown.findIndex(x => x.id === 0);

        if (index > -1) {
            dropdown.splice(index, 1);
        }
    }

    const closeDialog = () => {
        props.hideDialog();
    }

    const savePointAB = () => {
        props.setLoading(true);
        props.onSubmit()
    }

    const destionationABDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={closeDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={savePointAB} />
        </>
    )

    const hideDialog = () => {
        props.setLoading(true);
        props.hideDialog()
    }

    const onCheckedChange = (e: any, name: string) => {
        const val: boolean = (e.target && e.target.checked) || false;

        let destinationABProperties = { ...props.destinationAB } as any;
        destinationABProperties[name] = val;
        props.setDestinationAB(destinationABProperties);
    }

    const onSelectedCamera = (e: any, property: string) => {
        const val = (e.target && e.target.value) || "";

        onDropdownValueChange(val, property);
    }

    const onDropdownValueChange = (val: any, name: string) => {
        let destinationAB = { ...props.destinationAB } as any;

        var newLocation: DropdownInfo = {
            description: '',
            id: val
        };

        destinationAB[`${name}`] = newLocation;

        props.setDestinationAB(destinationAB);
    }

    const onLocationPointAChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || "";

        onDropdownValueChange(val, name);
        onSelectedLocationPointA(val);
    }

    const onLocationPointBChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || "";

        onDropdownValueChange(val, name);
        onSelectedLocationPointB(val);
    }

    const validateName = (name: string): boolean => {
        const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ]+(\s[A-Za-zÀ-ÖØ-öø-ÿ]+)+$/;
        return nameRegex.test(name) && !name.includes('@');
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setIsNameValid(validateName(value));
        onInputChange(e, 'name', props.destinationAB, props.setDestinationAB);
    };

    return (
        <div className="p-grid crud-demo">
            <ToastContainer />
            <div className="card">
                <Dialog visible={props.visible} style={{ width: "450px" }} header={props.dialogHeader} modal className="p-fluid" footer={destionationABDialogFooter} onHide={hideDialog}>
                    <Toast ref={toast} />
                    <>
                        <span className="titulo-categoria">USUÁRIO:</span>
                        <div className="categoria">
                            <div className="categoria mb-2">
                                <strong>Nome completo do responsável pelo cadastro *</strong>
                                <InputText
                                    id="name"
                                    value={props.destinationAB.name}
                                    onChange={handleNameChange}
                                    required
                                    autoFocus
                                    className={classNames({ 'p-invalid': props.submitted && (!props.destinationAB.name || !isNameValid) })}
                                />
                            </div>
                        </div>
                    </>
                    <span className="titulo-categoria">CONFIGURAÇÃO:</span>
                    <div className="categoria">
                        <div className="formgrid grid">
                            <div className="p-field col-6">
                                <strong>Localidade Ponto A:</strong>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-map-marker"></i>
                                    </span>
                                    {
                                        <Dropdown
                                            emptyMessage="Nenhuma Localidade Encontrada"
                                            disabled={loadingLocations || !isNameValid}
                                            optionLabel="description"
                                            optionValue="id"
                                            options={locations}
                                            placeholder="Selecionar localidade A"
                                            value={props.destinationAB.locationPointA.id}
                                            onChange={(e) => onLocationPointAChange(e, "locationPointA")}
                                            className={classNames({ "p-invalid": props.submitted && props.destinationAB.locationPointA.id === 0 })}
                                        />
                                    }
                                </div>
                                {props.submitted && props.destinationAB.locationPointA.id === 0 && <small className="p-invalid">Selecione uma Localidade.</small>}
                            </div>
                            <div className="p-field col-6">
                                <strong>Localidade Ponto B:</strong>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-map-marker"></i>
                                    </span>
                                    {
                                        <Dropdown
                                            emptyMessage="Nenhuma Localidade Encontrada"
                                            disabled={loadingLocations || !isNameValid}
                                            optionLabel="description"
                                            optionValue="id"
                                            options={locations}
                                            placeholder="Selecionar localidade B"
                                            value={props.destinationAB.locationPointB.id}
                                            onChange={(e) => onLocationPointBChange(e, 'locationPointB')}
                                            className={classNames({ "p-invalid": props.submitted && props.destinationAB.locationPointB.id === 0 })}
                                        />
                                    }
                                </div>
                                {props.submitted && props.destinationAB.locationPointB.id === 0 && <small className="p-invalid">Selecione uma Localidade.</small>}
                            </div>
                        </div>
                        <div className="formgrid grid">
                            <div className="p-field col-6">
                                <strong>Câmera Ponto A:</strong>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-camera"></i>
                                    </span>
                                    {
                                        <Dropdown
                                            emptyMessage="Nenhuma Câmera Encontrada"
                                            disabled={(loadingCameras || props.destinationAB.locationPointA.id === 0 || !isNameValid)}
                                            optionLabel="description"
                                            optionValue="id"
                                            options={camerasPointA}
                                            placeholder="Selecionar câmera A"
                                            value={props.destinationAB.cameraPointA.id}
                                            onChange={(e) => onSelectedCamera(e, 'cameraPointA')}
                                            className={classNames({ "p-invalid": props.submitted && props.destinationAB.cameraPointA.id === 0 })}
                                        />
                                    }
                                </div>
                                {props.submitted && props.destinationAB.cameraPointA.id === 0 && <small className="p-invalid">Selecione uma Câmera.</small>}
                            </div>
                            <div className="p-field col-6">
                                <strong>Câmera Ponto B:</strong>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-camera"></i>
                                    </span>
                                    {
                                        <Dropdown
                                            emptyMessage="Nenhuma Câmera Encontrada"
                                            disabled={(loadingCameras || props.destinationAB.locationPointB.id === 0 || !isNameValid)}
                                            optionLabel="description"
                                            optionValue="id"
                                            options={camerasPointB}
                                            placeholder="Selecionar câmera B"
                                            value={props.destinationAB.cameraPointB.id}
                                            onChange={(e) => onSelectedCamera(e, 'cameraPointB')}
                                            className={classNames({ "p-invalid": props.submitted && props.destinationAB.cameraPointB.id === 0 })}
                                        />
                                    }
                                </div>
                                {props.submitted && props.destinationAB.cameraPointB.id === 0 && <small className="p-invalid">Selecione uma Câmera.</small>}
                            </div>
                        </div>
                    </div>
                    {props.isEditingDestinationAB &&
                        <div className="p-field-checkbox">
                            <Checkbox className="mr-2" inputId="isActive" name="option" checked={props.destinationAB.isActive} onChange={(e) => onCheckedChange(e, "isActive")} />
                            <span>Ativo</span>
                        </div>
                    }
                </Dialog>
            </div>
        </div>
    )
}
