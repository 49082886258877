import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

interface LegendProps {
    intensityFactor: number;
    radius: number;
    blur: number;
    maxZoom: number;
    maxVehicles: number; // Valor máximo vindo das câmeras, limitado a 1000.
}

const Legend: React.FC<LegendProps> = React.memo(({ intensityFactor, radius, blur, maxZoom, maxVehicles }) => {
    const map = useMap();

    useEffect(() => {
        // Criando uma instância de L.Control para a legenda
        const legend = new L.Control({ position: 'bottomright' });

        // Definindo o método onAdd da legenda
        legend.onAdd = function () {
            const div = L.DomUtil.create('div', 'info legend');

            // Define o valor máximo com base nos ajustes do slide e maxVehicles
            const adjustedMax = Math.min(maxVehicles, 1000) * intensityFactor * (blur / 20) * (radius / 30) * (maxZoom / 15);
            const step = adjustedMax / 6;  // Divide o valor máximo ajustado em 6 intervalos

            // Gerar os intervalos dinâmicos usando os ajustes combinados
            const grades = Array.from({ length: 7 }, (_, i) => Math.round(i * step));

            // Função para gerar a cor correspondente a cada intervalo
            const getColor = (d: number) => {
                return d > grades[5] ? '#FF0000' :
                    d > grades[4] ? '#FF4500' :
                    d > grades[3] ? '#FFA500' :
                    d > grades[2] ? '#FFFF00' :
                    d > grades[1] ? '#00FF00' :
                    d > grades[0] ? '#00FFFF' :
                                    '#0000FF';
            };

            // Definindo o conteúdo HTML da legenda
            div.innerHTML = `
                <h3>Legenda</h3>
                <i style="background:green;width:20px;height:20px;display:inline-block;"></i> Câmera Ativa<br>
                <i style="background:orange;width:20px;height:20px;display:inline-block;"></i> Câmera Ativa (SignalR Inativo)<br>
                <i style="background:red;width:20px;height:20px;display:inline-block;"></i> Câmera Inativa<br><br>
                <h4>Heatmap (Veículos)</h4>
            `;

            // Loop para gerar a legenda do heatmap com base nos intervalos e nas cores
            for (let i = 0; i < grades.length; i++) {
                div.innerHTML +=
                    `<i style="background:${getColor(grades[i])};width:20px;height:20px;display:inline-block;"></i> ` +
                    grades[i] + (grades[i + 1] ? '&ndash;' + grades[i + 1] + '<br>' : '+');
            }

            return div;
        };

        legend.addTo(map);

        return () => {
            legend.remove();
        };
    }, [map, intensityFactor, radius, blur, maxZoom, maxVehicles]);

    return null;
});

export default Legend;
