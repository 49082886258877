import React, { Fragment, useState, useEffect } from "react";
import CameraMarker from "./camera-marker";
import { Camera, plateEventResponse } from "../../types/types";
import { useMapEvents, useMap } from "react-leaflet";
import L from 'leaflet';

interface VisibleCamerasProps {
    cameraEvents: { [key: number]: plateEventResponse | null }; // Mapeamento de cameraId para evento
    cameras: Camera[];  // Lista de câmeras
    readPlate: plateEventResponse;
    onSelectedCamera: (val: any) => void;  // Função chamada ao selecionar uma câmera
    activePopupCameraId: number | null;  // ID da câmera que deve mostrar o popup
    vehicleCountsByCamera: { [cameraId: number]: any }; // Contagens de veículos por câmera
}

// Hook para ajustar coordenadas, se necessário
const adjustCoordinates = (lat: number, lng: number): [number, number] => {
    return [lat > 0 ? -lat : lat, lng > 0 ? -lng : lng];
};

// Hook para determinar câmeras visíveis no mapa com base nos eventos de movimentação e zoom
const useVisibleCameras = (cameras: Camera[], delay: number = 500): Camera[] => {
    const map = useMap();  // Hook para acessar o mapa
    const [visibleCameras, setVisibleCameras] = useState<Camera[]>([]);

    const handleVisibleCameras = () => {
        const bounds = map.getBounds();  // Obtém os limites atuais do mapa

        // Filtra as câmeras que estão dentro dos limites visíveis do mapa
        const filteredCameras = cameras.filter((camera) => {
            const [adjustedLatitude, adjustedLongitude] = adjustCoordinates(camera.numLatitude, camera.numLongitude);
            const latLng = L.latLng(adjustedLatitude, adjustedLongitude);
            return bounds.contains(latLng);  // Verifica se a câmera está dentro dos limites do mapa
        });

        setVisibleCameras(filteredCameras);  // Atualiza as câmeras visíveis
    };

    useMapEvents({
        // Eventos que disparam o recalculo das câmeras visíveis
        moveend: () => handleVisibleCameras(),   // Quando o movimento do mapa termina
        zoomend: () => handleVisibleCameras(),   // Quando o zoom do mapa termina
    });

    useEffect(() => {
        handleVisibleCameras();  // Filtra câmeras quando o componente é montado
    }, [cameras]);

    return visibleCameras;
};

// Componente que exibe câmeras visíveis no mapa
const VisibleCameras: React.FC<VisibleCamerasProps> = React.memo((props: VisibleCamerasProps) => {
    const visibleCameras = useVisibleCameras(props.cameras, 500);  // Adiciona um delay de 500ms na filtragem das câmeras

    return (
        <Fragment>
            {visibleCameras.map((camera) => (
                <CameraMarker
                    cameraEvents={props.cameraEvents}
                    key={camera.id}
                    camera={camera}
                    readPlate={props.readPlate}
                    activePopupCameraId={props.activePopupCameraId}
                    onSelectedCamera={props.onSelectedCamera}  // Passa a função de seleção de câmera como prop
                    vehicleCount={props.vehicleCountsByCamera[camera.id]}  // Passa apenas a contagem de veículos relativa à câmera
                />
            ))}
        </Fragment>
    );
});

export default VisibleCameras;
