import React, { useState } from "react";
import { PageHeader } from "../../../views/page-header";

export const ReportPlate = (props: any) => {

    const [plate, setPlate] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const handleSearch = () => {
        // axios.get(`/api/v2/vehicle/search?placa=${plate}`)
        //     .then(response => {
        //         setSearchResults(response.data);
        //     })
        //     .catch(error => {
        //         console.error('Erro ao buscar placas', error);
        //     });
    };

    return (
        <React.Fragment>
            <div className="grid">
                <div className="col-12">
                    <PageHeader
                        title="PESQUISA DE PLACAS COM FILTROS"
                        icon="pi pi-car"
                    />
                    <div className="card">
                        <input
                            type="text"
                            value={plate}
                            onChange={(e) => setPlate(e.target.value)}
                            placeholder="Digite a placa"
                        />
                        <button onClick={handleSearch}>Buscar</button>

                        <ul>
                            {searchResults.map((result, index) => (
                                <li key={index}>{result.licensePlate} - {result.detectionTime}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
