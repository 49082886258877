// Componente que centraliza o mapa dinamicamente
import { useEffect } from 'react';
import { useMap } from 'react-leaflet';

interface DynamicMapCenterProps {
    latitude: number;
    longitude: number;
    zoom: number;
}
export const DynamicMapCenter: React.FC<DynamicMapCenterProps> = ({ latitude, longitude, zoom }) => {
    const map = useMap();

    useEffect(() => {
        if (latitude && longitude) {
            map.setView([latitude, longitude], zoom);
        }
    }, [latitude, longitude, zoom, map]);

    return null;
};
