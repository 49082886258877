import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { updatedCameraUpdateResponse } from '../../types/types';

export interface GraphStatusCameraProps {
    data: updatedCameraUpdateResponse[];
    width: number;
}

export const GraphStatusCamera = (props: GraphStatusCameraProps) => {
    const { data } = props;

    // Transformando os dados para o formato correto
    const processData = data.map(entry => ({
        timestamp: new Date(entry.lastUpdated).getTime(), // Verificar se 'lastUpdated' está correto
        Estado: entry.online ? 1 : 0  // Convertendo online para 1 (online) ou 0 (offline)
    }));

    // Função personalizada para o conteúdo do tooltip
    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            const timestamp = new Date(label).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            return (
                <div className="custom-tooltip">
                    <p className="label">{`Horário: ${timestamp}`}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <React.Fragment>
            <LineChart
                width={props.width || 600}
                height={100}
                data={processData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
                <CartesianGrid strokeDasharray="0" horizontal={false} vertical={false} />
                <XAxis
                    dataKey="timestamp"
                    type="number"
                    domain={['dataMin', 'dataMax']}
                    tickFormatter={(timestamp) => new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                />
                <YAxis />
                <Tooltip
                    content={<CustomTooltip />} // Usando o conteúdo personalizado
                />
                <Line type="stepAfter" dataKey="Estado" stroke="#8884d8" dot={false} />
            </LineChart>
        </React.Fragment >
    );
}

export default GraphStatusCamera;
