import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { ToastServices } from "../../../../services/utils/toast-services";
import { CamerasListDataTable } from "../data-tables/dsm-list-data-table-cameras";
import { cameraUpdateResponse, updatedCameraStatusUpdateRequest } from "../../types/types";
import { AuthServices } from "../../../../services/utils/auth-services";
import { DsmBodyProps } from "../../interfaces/DsmBodyProps";
import { FeatureServices } from "../../../../services/feature-services";
import { MonitoringServices } from "../../../../services/monitoring-services";


export const DsmBodyCameras = (props: DsmBodyProps) => {
    const _toastServices = new ToastServices();
    const _featureServices = new FeatureServices();
    const _monitoringServices = new MonitoringServices();
    const _authServices = new AuthServices();

    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedCameras, setSelectedCameras] = useState<cameraUpdateResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [locations, setLocations] = useState<[]>([]);
    const [cameras, setCameras] = useState<[]>([]);
    const [recent, setRecent] = useState<[]>([]);

    // Referências para armazenar intervalos e timeouts
    const intervalRef = useRef<number | undefined>();
    const timeoutRef = useRef<number | undefined>();

    _authServices.HandleAppAuth();

    useEffect(() => {

        loadLocations();

        if (selectedLocation) {
            loadCamerasByLocation(selectedLocation);
            loadCamerasUpdatedByLocation(selectedLocation);

            // Limpa timeout e intervalo anteriores antes de iniciar novos
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }

            // Set a timeout for 10 seconds, then start the interval
            timeoutRef.current = window.setTimeout(() => {
                intervalRef.current = window.setInterval(() => {
                    loadCamerasByLocation(selectedLocation);
                    loadCamerasUpdatedByLocation(selectedLocation);
                }, 10000); // 10 segundos
            }, 10000); // 10 segundos

            // Cleanup timeout and interval on unmount or when selectedLocation changes
            return () => {
                if (timeoutRef.current) {
                    clearTimeout(timeoutRef.current);
                }
                if (intervalRef.current) {
                    clearInterval(intervalRef.current);
                }
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedLocation]);


    const loadLocations = () => {
        setLoading(true);

        _featureServices.getLocationsByFeature('MONITOR-ALARM')
            .then((data: any) => {
                setLocations(data.data);
                setLoading(false);
            }).catch((error) => {
                _toastServices.showErrorRequestMessage(error);
                setLoading(false);
            });
    }

    const loadCamerasByLocation = (locationId: number) => {
        setLoading(true);
        _monitoringServices.getCamerasByFeatureAndLocation(locationId)
            .then((data: any) => {
                if (Array.isArray(data.data)) {
                    const sortedCameras = data.data.sort((a: any, b: any) => {
                        const nameA = (a.cameraName || '').toLowerCase();
                        const nameB = (b.cameraName || '').toLowerCase();

                        if (nameA < nameB) return -1;
                        if (nameA > nameB) return 1;
                        return 0;
                    });

                    setCameras(sortedCameras);
                } else {
                    console.error('Data is not an array:', data.data);
                }

                setLoading(false);
            }).catch(error => {
                _toastServices.showErrorRequestMessage(error);
                setLoading(false);
            });
    }


    const loadCamerasUpdatedByLocation = (locationId: number) => {
        setLoading(true);
        _monitoringServices.getCameraRecent(locationId)
            .then((data: any) => {
                console.log("recent", data.data)
                setRecent(data.data);
                setLoading(false);
            }).catch(error => {
                _toastServices.showErrorRequestMessage(error);
                setLoading(false);
            })
    }

    const onSelectedLocation = (e: { value: any }) => {
        setSelectedLocation(e.value);
        setCameras([]);
    }

    function updateCameraStatus(updatedCamera: updatedCameraStatusUpdateRequest): void {
        try {
            _monitoringServices.updateCameraStatus(updatedCamera)
                .then(response => {
                    _toastServices.showSuccesMessage("Status da câmera atualizado com sucesso:");
                    loadCamerasByLocation(selectedLocation)
                })
                .catch(error => {
                    _toastServices.showErrorMessage("Erro ao atualizar o status da câmera:");
                });
        } catch (error) {
            _toastServices.showErrorMessage("Erro inesperado ao atualizar o status da câmera:");
        }
    }


    function toggleActive(cameraUpdateResponse: cameraUpdateResponse): void {
        const updatedCamera: updatedCameraStatusUpdateRequest = {
            cameraId: cameraUpdateResponse.cameraId,
            activeMonitor: !cameraUpdateResponse.activeMonitor,
        };

        updateCameraStatus(updatedCamera);
    }

    return (
        <React.Fragment>
            <div className="card">
                <div className="grid">
                    <div className="col-2">
                        <div className="p-inputgroup mb-2">
                            <span className="p-inputgroup-addon">
                                <i className="pi pi-map-marker"></i>
                            </span>
                            <Dropdown
                                options={locations}
                                disabled={loading}
                                value={selectedLocation}
                                tooltip="Selecione uma localidade para carregar as cameras"
                                placeholder="Selecione uma localidade"
                                emptyMessage="Nenhuma mensagem encontrada"
                                optionValue="id"
                                optionLabel="description"
                                onChange={(e) => onSelectedLocation(e)}
                            />
                        </div>
                    </div>

                </div>
                <CamerasListDataTable
                    setGlobalFilter={setGlobalFilter}
                    cameras={cameras}
                    recent={recent}
                    selectedCameras={selectedCameras}
                    setSelectedCameras={setSelectedCameras}
                    loading={props.loading}
                    globalFilter={globalFilter}
                    toggleActive={toggleActive}
                />
            </div>
        </React.Fragment>
    );
};
