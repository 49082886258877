import classNames from "classnames";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Toolbar } from "primereact/toolbar";
import React, { useState, useEffect } from "react";
import { onCheckedChange, onInputChange, onInputDateChange } from "../../../../services/utils/input-services";
import { user } from "../../../Login/types/types";
import { UserLocationsDataTable } from "../data-tables/user-locations-data-table";
import "./../../styles/user.scss";
import { CreateUserLocationDialog } from "./create-user-location-dialog";
import { Calendar } from "primereact/calendar";

export interface CreateUserDialogProps {
    user: user;
    setUser: (user: user) => void;
    setUserDialog: (showUserDialog: boolean) => void;
    isEditingUser: boolean;
    visible: boolean;
    submitted: boolean;
    setSubmitted: (value: boolean) => void;
    onSubmit: () => void;
    hideDialog: () => void;
    dialogHeader: string;
}

export const CreateUserDialog = (props: CreateUserDialogProps) => {
    const [showNewLocationDialog, setShowNewLocationDialog] = useState<boolean>(false);

    // Se não houver data de expiração, defina a data atual mais um dia como valor padrão
    useEffect(() => {
        if (!props.user.expiration) {
            const defaultExpiration = new Date();
            defaultExpiration.setDate(defaultExpiration.getDate() + 1);  // Adiciona um dia à data atual
            props.setUser({ ...props.user, expiration: defaultExpiration });
        }
    }, [props.user.expiration, props.setUser]);

    const userDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={props.hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={(e) => props.onSubmit()} />
        </>
    )

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-">
                    <Button label="Nova Localidade" icon="pi pi-plus" className="p-button-success" onClick={(e) => openNewLocationDialog()} />
                </div>
            </React.Fragment>
        );
    }

    const closeNewLocationDialog = () => {
        setShowNewLocationDialog(false);
    }

    const openNewLocationDialog = () => {
        setShowNewLocationDialog(true);
    }

    const deleteLocation = (rowData: any) => {
        let locations = props.user.allowedLocations.filter((x) => x.locationId !== rowData.locationId);

        let _user = { ...props.user } as any;
        _user.allowedLocations = locations;

        props.setUser(_user);
    }

    const isOlderDateSelected = (selectedDate: Date) => {
        if (!selectedDate) return false;
        const today = new Date();
        return selectedDate < today;
    };

    return (
        <div className="p-grid crud-demo">
            <div className="card">
                <Dialog visible={props.visible} style={{ width: "450px" }} header={props.dialogHeader} modal className="p-fluid" footer={userDialogFooter} onHide={props.hideDialog}>
                    <span className="titulo-categoria">IDENTIFICAÇÃO:</span>
                    <div className="categoria">
                        <div className="grid">
                            <div className="p-field col-6">
                                <span>Nome*</span>
                                <InputText
                                    id="name"
                                    type="text"
                                    value={props.user.userName}
                                    onChange={(e) => onInputChange(e, "userName", props.user, props.setUser)}
                                    required
                                    autoFocus
                                    className={classNames({ "p-invalid": props.submitted && !props.user.userName })}
                                />
                                {props.submitted && !props.user.userName && <small className="p-invalid">Insira um Nome.</small>}
                            </div>
                            <div className="p-field col-6">
                                <span>Email*</span>
                                <InputText
                                    id="email"
                                    type="text"
                                    value={props.user.email}
                                    onChange={(e) => onInputChange(e, "email", props.user, props.setUser)}
                                    required
                                    autoFocus
                                    className={classNames({ "p-invalid": props.submitted && !props.user.email })}
                                />
                                {props.submitted && !props.user.email && <small className="p-invalid">Insira um Email.</small>}
                            </div>
                        </div>
                    </div>

                    <span className="titulo-categoria">CREDENCIAIS:</span>
                    <div className="categoria">
                        <div className="formgrid grid">
                            <div className="field col-6">
                                <span>Acesso*</span>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    <InputText
                                        id="access"
                                        type="text"
                                        value={props.user.userAccess}
                                        onChange={(e) => onInputChange(e, "userAccess", props.user, props.setUser)}
                                        required
                                        autoFocus
                                        className={classNames({ "p-invalid": props.submitted && !props.user.userAccess })}
                                    />
                                </div>
                                {props.submitted && !props.user.userAccess && <small className="p-invalid">Insira um Usuário.</small>}
                            </div>
                            <div className="field col-6">
                                <span>Senha*</span>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-lock"></i>
                                    </span>
                                    <Password
                                        value={props.user.password}
                                        toggleMask
                                        feedback={false}
                                        onChange={(e) => onInputChange(e, "password", props.user, props.setUser)}
                                        required
                                        autoFocus
                                        className={classNames({ "p-invalid": props.submitted && !props.user.password })}
                                    />
                                </div>
                                {props.submitted && !props.user.password && <small className="p-invalid">Insira uma senha.</small>}
                            </div>
                        </div>
                    </div>

                    <span className="titulo-categoria">TIPO USUÁRIO:</span>
                    <div className="categoria">
                        <div className="formgrid grid">
                            <div className="field col-6">
                                <div className="p-field-checkbox">
                                    <Checkbox
                                        className="mr-2"
                                        inputId="isAdministrator"
                                        name="option"
                                        checked={props.user.isAdministrator}
                                        onChange={(e) => onCheckedChange(e, "isAdministrator", props.user, props.setUser)}
                                    />
                                    <span>Administrador</span>
                                </div>
                            </div>
                        </div>
                        {!props.user.isAdministrator && (<>
                            <div className="formgrid grid">
                                <div className="field col-6">
                                    <div className="p-field-checkbox">
                                        <Checkbox
                                            className="mr-2"
                                            inputId="isTemporary"
                                            name="option"
                                            checked={props.user.isTemporary}
                                            onChange={(e) => onCheckedChange(e, "isTemporary", props.user, props.setUser)}
                                        />
                                        <span>Usuário Temporário</span>
                                    </div>
                                </div>

                                {props.user.isTemporary && <div className="field col-6">
                                    <span>Data de Expiração*</span>
                                    <Calendar
                                        value={
                                            props.user.expiration instanceof Date
                                                ? new Date(props.user.expiration.getTime() + (props.user.expiration.getTimezoneOffset() * 60000))
                                                : null
                                        }  // Ajuste para exibir no fuso local
                                        onChange={(e: any) => onInputDateChange(e, "expiration", props.user, props.setUser)}  // Atualiza o campo expiration
                                        dateFormat="dd/mm/yy"  // Formato da data
                                        showTime  // Exibe o seletor de horário
                                        hourFormat="24"  // Define o formato de 24 horas
                                        className={classNames({
                                            "p-invalid": isOlderDateSelected(props.user.expiration) && props.user.isTemporary && !props.user.isAdministrator
                                        })}  // Validação de data
                                    />

                                    {props.submitted && !props.user.expiration && props.user.isTemporary && !props.user.isAdministrator && <small className="p-invalid">Selecione uma data de expiração.</small>}
                                </div>}
                            </div>

                            <div className="formgrid grid">
                                <div className="field col-6">
                                    <div className="p-field-checkbox">
                                        <Checkbox
                                            className="mr-2"
                                            inputId="isRestrictedLocal"
                                            name="option"
                                            checked={props.user.isRestrictedLocal}
                                            onChange={(e) => onCheckedChange(e, "isRestrictedLocal", props.user, props.setUser)}
                                        />
                                        <span>Local Restrito</span>
                                    </div>
                                </div>
                                {props.user.isRestrictedLocal && <div className="field col-6">
                                    <span>Endereço IP*</span>
                                    <div className="p-inputgroup">
                                        <InputText
                                            id="ipAddressRestricted"
                                            type="text"
                                            value={props.user.ipAddressRestricted}
                                            onChange={(e) => onInputChange(e, "ipAddressRestricted", props.user, props.setUser)}
                                            required
                                            autoFocus
                                            className={classNames({ "p-invalid": props.submitted && !props.user.ipAddressRestricted && props.user.isRestrictedLocal && !props.user.isAdministrator })}
                                        />
                                    </div>
                                    {props.submitted && !props.user.ipAddressRestricted && props.user.isRestrictedLocal && !props.user.isAdministrator && <small className="p-invalid">Insira um endereço de IP válido.</small>}
                                </div>}
                            </div>
                            <div className="formgrid grid">
                                <div className="field col-4">
                                    <div className="p-field-checkbox">
                                        <Checkbox
                                            className="mr-2"
                                            inputId="isRestrictedTime"
                                            name="option"
                                            checked={props.user.isRestrictedTime}
                                            onChange={(e) => onCheckedChange(e, "isRestrictedTime", props.user, props.setUser)}
                                        />
                                        <span>Horário Restrito</span>
                                    </div>
                                </div>
                                {props.user.isRestrictedTime && <>
                                    <div className="field col-4">
                                        <span>Horário inicial de Acesso*</span>
                                        <Calendar
                                            value={props.user.initialTime ? new Date(props.user.initialTime.getTime() + (props.user.initialTime.getTimezoneOffset() * 60000)) : null}  // Ajuste para exibir no fuso local
                                            onChange={(e: any) => onInputDateChange(e, "initialTime", props.user, props.setUser)}  // Atualiza o campo initialTime
                                            timeOnly  // Apenas o horário
                                            hourFormat="24"  // Define o formato de 24 horas
                                            className={classNames({ "p-invalid": !props.user.initialTime && props.user.isRestrictedTime && !props.user.isAdministrator })}  // Validação de horário
                                        />
                                        {props.submitted && !props.user.initialTime && props.user.isRestrictedTime && !props.user.isAdministrator && <small className="p-invalid">Selecione um horário inicial.</small>}
                                    </div>
                                    <div className="field col-4">
                                        <span>Horário final de Acesso*</span>
                                        <Calendar
                                            value={props.user.endTime ? new Date(props.user.endTime.getTime() + (props.user.endTime.getTimezoneOffset() * 60000)) : null}  // Ajuste para exibir no fuso local
                                            onChange={(e: any) => onInputDateChange(e, "endTime", props.user, props.setUser)}  // Atualiza o campo endTime
                                            timeOnly  // Apenas o horário
                                            hourFormat="24"  // Define o formato de 24 horas
                                            className={classNames({ "p-invalid": !props.user.endTime && props.user.isRestrictedTime && !props.user.isAdministrator })}  // Validação de horário
                                        />
                                        {props.submitted && !props.user.endTime && props.user.isRestrictedTime && !props.user.isAdministrator && <small className="p-invalid">Selecione um horário final.</small>}
                                    </div>
                                </>}
                            </div>
                        </>)}

                    </div>

                    <span className="titulo-categoria">LOCALIDADES:</span>
                    <div className={'categoria'}>
                        <CreateUserLocationDialog
                            visible={showNewLocationDialog}
                            closeDialog={closeNewLocationDialog}
                            allowedUserLocations={props.user.allowedLocations}
                            isEditing={props.isEditingUser}
                        />
                        <Toolbar className="p-mb-4" left={leftToolbarTemplate} />
                        <UserLocationsDataTable
                            allowedLocations={props.user.allowedLocations}
                            deleteLocation={deleteLocation}
                        />
                    </div>
                    {props.submitted && props.user.allowedLocations.length <= 0 && <small className="p-invalid">Selecione pelo menos uma localidade.</small>}
                </Dialog>
            </div>
        </div>
    )
};
