import React from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'primeicons/primeicons.css'; // Certifique-se de que os PrimeIcons estão importados

// Função para centralizar o mapa com a localização atual
const LocateUserButton: React.FC = () => {
    const map = useMap();  // Obtenha o mapa

    const locateUser = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    map.setView([latitude, longitude], 13); // Centraliza e aplica zoom
                    L.marker([latitude, longitude])
                        .addTo(map)
                        .bindPopup("Você está aqui.")
                        .openPopup();
                },
                (error) => {
                    console.error('Geolocalização falhou', error);
                }
            );
        } else {
            alert("Geolocalização não é suportada pelo seu navegador.");
        }
    };

    return (
        <button
            onClick={locateUser}
        >
            <i className="pi pi-map-marker" style={{ fontSize: '1.5em' }}></i> {/* Ícone do PrimeIcons */}
            Sua localização {/* Texto ao lado do ícone */}
        </button>
    );
};

export default LocateUserButton;
