import React, { useEffect, useRef, useState } from 'react';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { applicationResponse, updatedDSMApplicationResponse } from '../../types/types';
import { TimeSinceUpdate } from '../time/time-since-update';
import { GraphStatus, GraphStatusProps } from '../graph/graph-status';
import '../../styles/dsm-monitor.scss';

export interface DsmListDataTableProps {
    locations: applicationResponse[];
    recent: updatedDSMApplicationResponse[];
    selectedLocations: applicationResponse[];
    setSelectedLocations: (selectedLocations: applicationResponse[]) => void;
    loading: boolean;
    globalFilter: string;
    setGlobalFilter: (val: any) => void;
    editLocation: (rowData: applicationResponse) => void;
    confirmDeleteLocation: (rowData: applicationResponse) => void;
    confirmDeleteSelected: () => void;
    openNew: () => void;
}

export const DsmListDataTable = (props: DsmListDataTableProps) => {
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const [chartWidth, setChartWidth] = useState<number>(600);

    useEffect(() => {
        const resizeListener = () => {
            if (chartContainerRef.current) {
                const newWidth = chartContainerRef.current.clientWidth;
                setChartWidth(newWidth);
            }
        };

        // Executa o resize imediatamente após a renderização inicial
        resizeListener();

        // Executa o resize após um pequeno delay para garantir o dimensionamento correto
        setTimeout(resizeListener, 5000);
        setTimeout(resizeListener, 7000);

        // Adiciona listener de resize para eventos futuros
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        };
    }, []);


    const header = (
        <div className="table-header">
            <h5 className="p-m-2">Servidores</h5>
        </div>
    );

    const getColor = (online?: boolean) => {
        if (online) {
            return '#00FF00'; // Verde fluorescente
        } else {
            return '#FF0000'; // Vermelho fluorescente
        }
    };

    const getStatus = (online?: boolean) => {
        if (online) {
            return 'ONLINE';
        } else {
            return 'OFFLINE';
        }
    };

    const nameBodyTemplate = (applicationResponse: applicationResponse) => {
        return <>{applicationResponse.dsmApplicationName}</>;
    };

    const localBodyTemplate = (applicationResponse: applicationResponse) => {
        return <>{applicationResponse.location.locationName}</>;
    };

    const typeBodyTemplate = (applicationResponse: applicationResponse) => {
        return (
            <>
                <div style={{ color: getColor(applicationResponse.online) }} className='dsm-monitor-justify'>{applicationResponse.type}</div>
            </>
        );
    };

    const modoBodyTemplate = (applicationResponse: applicationResponse) => {
        return (
            <>
                <div style={{ color: getColor(applicationResponse.online) }} className='dsm-monitor-justify'>{applicationResponse.modo}</div>
            </>
        );
    };

    const statusBodyTemplate = (applicationResponse: applicationResponse) => {
        return (
            <div>
                <div className='dsm-monitor-justify flex-container'>
                    <div style={{ color: getColor(applicationResponse.online) }}>{getStatus(applicationResponse.online)} </div>
                    <div className="pi pi-fw pi-bell" style={{ color: getColor(applicationResponse.online) }}></div>
                </div>
            </div>
        );
    };

    const timeBodyTemplate = (applicationResponse: applicationResponse) => {
        return (
            <>
                <TimeSinceUpdate style={{ color: getColor(applicationResponse?.online) }} className='dsm-monitor-justify' lastUpdated={applicationResponse.lastUpdated} />
            </>
        )
    };

    const countBodyTemplate = (applicationResponse: applicationResponse) => {
        // Filtra os dados recent para encontrar correspondências com applicationResponse.dsmApplicationId
        const recentData = props.recent.filter(recent => recent.dsmApplicationId === applicationResponse.dsmApplicationId && applicationResponse.activeMonitor);

        // Conta o número de vezes que o estado foi offline
        const offlineCount = recentData.filter(data => !data.online).length;

        return (
            <div className="dsm-monitor-center">
                <span>{offlineCount}</span>
            </div>
        );

    };

    const graphBodyTemplate = (applicationResponse: applicationResponse) => {
        // Filtra os dados recent para encontrar correspondências com applicationResponse.dsmApplicationId
        const recentData = props.recent.filter(recent => recent.dsmApplicationId === applicationResponse.dsmApplicationId);

        return (
            <div ref={chartContainerRef} className='dsm-monitor-center'>
                {recentData.length > 0 ? (
                    <GraphStatus width={chartWidth} data={recentData as GraphStatusProps['data']} />
                ) : (
                    <div>No Data</div>
                )}
            </div>
        );
    };

    return (
        <React.Fragment>
            <DataTable
                value={props.locations}
                selection={props.selectedLocations}
                onSelectionChange={(e: any) => props.setSelectedLocations(e.value as applicationResponse[])}
                dataKey="id"
                paginator
                rows={10}
                selectionMode='multiple'
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                currentPageReportTemplate="Mostrando {first} de {last}, de um total de {totalRecords} usuários"
                globalFilter={props.globalFilter}
                emptyMessage="Nenhum usuário encontrado."
                header={header}
                loading={props.loading}>
                <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                <Column field="nomServidor" header="Nome" sortable body={nameBodyTemplate}></Column>
                <Column field="locServidor" header="Local" sortable body={localBodyTemplate}></Column>
                <Column field="tipServidor" header="Tipo" sortable body={typeBodyTemplate}></Column>
                <Column field="modServidor" header="Modo" sortable body={modoBodyTemplate}></Column>
                <Column field="nomEstado" header="Estado" sortable body={statusBodyTemplate}></Column>
                <Column field="tempEstado" header="Tempo de atividade" sortable body={timeBodyTemplate}></Column>
                <Column field="countEstado" header="Desconexões" sortable body={countBodyTemplate}></Column>
                <Column field="graphEstado" headerStyle={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }} header="Gráfico" sortable body={graphBodyTemplate}></Column>
                {/* <Column body={actionBodyTemplate}></Column> */}
            </DataTable>
        </React.Fragment>
    );
};
