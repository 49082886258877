import React, { useState } from "react";
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { translateColor } from "../../../services/utils/input-services";
import { VehicleDetection } from "../types/types";

export interface VehicleDetectionListDataTableProps {
    loading: boolean;
    globalFilter: string;
    setGlobalFilter: (val: any) => void;
    vehicleHistory: VehicleDetection[];
}

export const VehicleDetectionListDataTable = (props: VehicleDetectionListDataTableProps) => {
    // Estado para armazenar a seleção dos itens
    const [selectedVehicles, setSelectedVehicles] = useState<VehicleDetection[]>([]);

    const header = (
        <div className="table-header">
            <h5 className="p-m-2">Veículos</h5>
        </div>
    );

    const licenseBodyTemplate = (vehicle: VehicleDetection) => {
        return <>{vehicle.licensePlate}</>;
    };

    const nameBodyTemplate = (vehicle: VehicleDetection) => {
        return <>{vehicle.cameraName}</>;
    };

    const locationBodyTemplate = (vehicle: VehicleDetection) => {
        return <>{vehicle.locationName}</>;
    };

    const colorBodyTemplate = (vehicle: VehicleDetection) => {
        const colorStyle = {
            backgroundColor: vehicle.vehicleColor.toLowerCase(),  // Define a cor de fundo com o valor de vehicleColor
            width: '20px',
            height: '20px',
            display: 'inline-block',
            marginRight: '8px',  // Adiciona um pequeno espaço entre o quadrado e o nome da cor
            borderRadius: '4px', // Faz as bordas ligeiramente arredondadas
        };

        return (
            <>
                <i style={colorStyle}></i>
                {translateColor(vehicle.vehicleColor)}
            </>
        );
    };

    const previewImageBodyTemplate = (vehicle: VehicleDetection) => {
        return (
            <div style={{ textAlign: "center" }}>
                {/* Verifica se há um caminho de imagem antes de exibir a pré-visualização com o link de download */}
                {vehicle.imagePath ? (
                    <a href={vehicle.imagePath} download target="_blank" rel="noopener noreferrer">
                        <img
                            src={vehicle.imagePath} // Usa a URL da imagem
                            alt="Pré-visualização"
                            style={{
                                width: '50px', // Largura da miniatura
                                height: 'auto',
                                borderRadius: '4px', // Borda levemente arredondada para estética
                                cursor: 'pointer', // Estilo de cursor para indicar que é clicável
                            }}
                        />
                    </a>
                ) : (
                    <span>Sem imagem</span>
                )}
            </div>
        );
    };



    const typeBodyTemplate = (vehicle: VehicleDetection) => {
        return <>{vehicle.vehicleType}</>;
    };

    const brandBodyTemplate = (vehicle: VehicleDetection) => {
        return <>{vehicle.vehicleBrand}</>;
    };

    return (
        <React.Fragment>
            <Toolbar className="p-mb-4"></Toolbar>
            {/* Tabela de Resultados */}
            <DataTable
                value={props.vehicleHistory}
                paginator
                rows={10}
                loading={props.loading}
                selection={selectedVehicles} // Adiciona o estado de seleção
                onSelectionChange={(e:any) => setSelectedVehicles(e.value)} // Atualiza o estado de seleção
                selectionMode="multiple"
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                currentPageReportTemplate="Mostrando {first} de {last}, de um total de {totalRecords} veículos localizados"
                globalFilter={props.globalFilter || ""}
                emptyMessage="Nenhum veículo encontrado."
                header={header}
            >
                <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column> {/* Coluna de seleção */}
                <Column field="licensePlate" header="Placa" sortable body={licenseBodyTemplate}></Column>
                <Column field="detectionTime" header="Data e Hora" body={(rowData) => new Date(rowData.detectionTime).toLocaleString()} />
                <Column field="camera.CameraName" header="Câmera" sortable body={nameBodyTemplate}></Column>
                <Column field="locationId" header="Localidade" sortable body={locationBodyTemplate}></Column>
                <Column field="vehicleType" header="Tipo" sortable body={typeBodyTemplate}></Column>
                <Column field="vehicleColor" header="Cor" sortable body={colorBodyTemplate}></Column>
                <Column field="previewImage" header="Pré-visualização" body={previewImageBodyTemplate}></Column>
                <Column field="vehicleBrand" header="Marca" sortable body={brandBodyTemplate}></Column>
            </DataTable>
        </React.Fragment>
    );
};
