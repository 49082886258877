import React, { useEffect, useRef, useState } from "react";
import { DropdownInfo } from "../../../models/types";
import { LocationServices } from "../../../services/location-services";
import { SignalRLiveMap } from "./signal-r-live-map";
import { MapServices } from "../../../services/map-services";
import { Camera, location, cardResponse } from "../types/types";
import '../styles/map.css';
import { DropdownFilters } from "./filters/dropdown-filters";
import { Slide } from "./components/slide-map";

interface MapProps {
}

export const Map: React.FC<MapProps> = (props: MapProps) => {
    const today: Date = new Date();
    const _locationServices = new LocationServices();
    const _mapServices = new MapServices();

    const [cameras, setCameras] = useState<Camera[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const [date, setDate] = useState<Date>(today);
    const [showPdfDialog, setShowPdfDialog] = useState<boolean>(false);
    const [selectedTimeSpan, setSelectedTimeSpan] = useState<number>(15);
    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [listLocations, setListLocations] = useState<location[]>([]);
    const [loadingLocations, setLoadingLocations] = useState<boolean>(true);
    const [loadingCameras, setLoadingCameras] = useState<boolean>(true);
    const [selectedCamera, setSelectedCamera] = useState<number>(0);
    const [camerasList, setCamerasList] = useState<DropdownInfo[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [signalRConnected, setSignalRConnected] = useState<boolean>(false);

    // Agora `selectedVehicleTypes` é um array de chaves de `cardResponse`
    const [selectedVehicleTypes, setSelectedVehicleTypes] = useState<(keyof cardResponse)[]>([
        'quantityCar',
        'quantityMotorBike',
        'quantityTruck',
        'quantityBus',
        'quantityPickup',
        'quantityOther',
    ]);

    const toolbarRef = useRef<HTMLDivElement>(null);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const slideRef = useRef<HTMLDivElement>(null);
    const [mapTopOffset, setMapTopOffset] = useState<number>(0);

    // Estados para o Heatmap controlado pelo slide
    const [intensityFactor, setIntensityFactor] = useState<number>(1); // Fator de Intensidade
    const [radius, setRadius] = useState<number>(50);  // Raio
    const [blur, setBlur] = useState<number>(35);     // Blur
    const [maxZoom, setMaxZoom] = useState<number>(17);  // MaxZoom

    const lastSelectedCamera = useRef<number>(0);
    const lastSelectedLocation = useRef<number>(0);
    const lastSelectedDate = useRef<Date>(today);
    const lastListLocations = useRef<location[]>([]);

    lastSelectedDate.current = date;
    lastSelectedCamera.current = selectedCamera;
    lastSelectedLocation.current = selectedLocation;
    lastListLocations.current = listLocations;

    // Carregar locations e câmeras
    useEffect(() => {
        getLocations();
        getCameras();
    }, []);

    // Atualiza a altura do mapa com base nas alturas do Toolbar e Dropdown
    useEffect(() => {
        const updateMapHeight = () => {
            const toolbarHeight = toolbarRef.current?.offsetHeight || 0;
            const dropdownHeight = dropdownRef.current?.offsetHeight || 0;
            setMapTopOffset(toolbarHeight + dropdownHeight);
        };

        updateMapHeight();

        window.addEventListener("resize", updateMapHeight);

        return () => {
            window.removeEventListener("resize", updateMapHeight);
        };
    }, []);

    const getLocations = () => {
        setLoadingLocations(true);
        _locationServices.getActiveMapLocations().then((response: any) => {
            let locations: DropdownInfo[] = [{ description: "TODAS LOCALIDADES", id: 0 }];
            response.data.forEach((location: any) => {
                locations.push({
                    description: location.description,
                    id: location.id,
                } as DropdownInfo);
            });

            let list_locations: location[] = [];
            response.data.forEach((location: any) => {
                list_locations.push({
                    id: location.id,
                    latitude: location.latitude,
                    longitude: location.longitude
                } as location);
            });
            setLocations(locations);
            setListLocations(list_locations);

            setLoadingLocations(false);
        });
    };

    const getCameras = () => {
        setLoading(true);
        _mapServices.getCameras()
            .then((data) => {
                let cameras = data.data;
                let list_cameras: DropdownInfo[] = [{ description: "TODAS CAMERAS", id: 0 }];
                cameras.forEach((camera: Camera) => {
                    list_cameras.push({
                        description: camera.dscCamera,
                        id: camera.id,
                    } as DropdownInfo);
                });

                setCameras(cameras);
                setCamerasList(list_cameras);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const getListCameras = (locationId: number) => {
        _mapServices.getCamerasDataByLocation(locationId)
            .then((data) => {
                let cameras = data.data;
                let list_cameras: DropdownInfo[] = [{ description: "TODAS CAMERAS", id: 0 }];
                cameras.forEach((camera: Camera) => {
                    list_cameras.push({
                        description: camera.dscCamera,
                        id: camera.id,
                    } as DropdownInfo);
                });

                setCameras(cameras);
                setCamerasList(list_cameras);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };

    const onSelectedLocation = (e: { value: any }) => {
        const selectedItem = listLocations.find(location => location.id === e.value);

        if (selectedItem) {
            setSelectedLocation(e.value);
            setSelectedCamera(0);
            getListCameras(e.value);
        } else if (e.value === 0) {
            setSelectedLocation(0);
            getCameras();
        }
    };

    const onSelectedCamera = (e: { value: any }) => {
        setSelectedCamera(e.value);
    }

    const onSelectedDate = (e: { value: any }) => {
        setDate(e.value);
    }

    // Handler para os tipos de veículos selecionados
    const onSelectedVehicleTypes = (selectedTypes: (keyof cardResponse)[]) => {
        setSelectedVehicleTypes(selectedTypes);
    };

    if (loading) {
        return <div>Loading map...</div>;
    }

    return (
        <div className="map-container">

            {/* Dropdown Filters com referência */}
            <div ref={dropdownRef} className="dropdown-filters">
                <DropdownFilters
                    date={date}
                    today={today}
                    cameras={camerasList}
                    locations={locations}
                    onSelectedLocation={onSelectedLocation}
                    showPdfDialog={showPdfDialog}
                    loadingCameras={loadingCameras}
                    onSelectedDate={onSelectedDate}
                    selectedCamera={selectedCamera}
                    selectedTimeSpan={selectedTimeSpan}
                    setShowPdfDialog={setShowPdfDialog}
                    loadingLocations={loadingLocations}
                    onSelectedCamera={onSelectedCamera}
                    selectedLocation={selectedLocation}
                    onSelectedTimeSpan={setSelectedTimeSpan}
                    signalRConnected={signalRConnected}
                    selectedVehicleTypes={selectedVehicleTypes}  // Passa os tipos de veículos selecionados
                    onSelectedVehicleTypes={onSelectedVehicleTypes}  // Função para selecionar tipos de veículos
                />
            </div>

            {/* Slide Component para controlar parâmetros */}
            <div ref={slideRef} className="slide">
                <Slide
                    intensityFactor={intensityFactor} setIntensityFactor={setIntensityFactor}
                    radius={radius} setRadius={setRadius}
                    blur={blur} setBlur={setBlur}
                    maxZoom={maxZoom} setMaxZoom={setMaxZoom}
                />
            </div>

            {/* Mapa preenchendo o espaço restante */}
            <div className="map-content">
                <SignalRLiveMap
                    loading={loading}
                    cameras={cameras}
                    selecteDate={lastSelectedDate.current}
                    selectedCamera={lastSelectedCamera.current}
                    selectedLocation={lastSelectedLocation.current}
                    listLocations={lastListLocations.current}
                    setSignalRConnected={setSignalRConnected}
                    onSelectedCamera={onSelectedCamera}
                    selectedVehicleTypes={selectedVehicleTypes}  // Passa os tipos de veículos selecionados para o mapa
                    intensityFactor={intensityFactor}  // Fator de Intensidade
                    radius={radius}  // Raio do Heatmap
                    blur={blur}  // Blur do Heatmap
                    maxZoom={maxZoom}  // MaxZoom do Heatmap
                />
            </div>
        </div>
    );
};
