import React from "react";
import { PageHeader } from "../../../views/page-header";


export const Documentation = (props: any) => {

    return (
        <React.Fragment>
            <div className="grid">
                <div className="col-12">
                    <PageHeader
                        title="DOCUMENTAÇÃO"
                        icon="pi pi-book"
                    />
                    <div className="card">


                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
