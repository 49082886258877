import React, { useState } from 'react';
import { Button } from 'primereact/button';
import { PerformanceGraph } from './performance-graph';
import { HealthGraph } from './health-graph';
import '../../styles/tab-graph.scss';

export interface TabGraphProps {
    date: Date,
    location: number,
    camera: number,
    lineOptions: any
}

export const TabGraph = (props: TabGraphProps): JSX.Element => {

    // Estado para gerenciar a aba ativa
    const [activeTab, setActiveTab] = useState<'performance' | 'health'>('performance');

    return (
        <React.Fragment>
            <div className="col-12 lg:col-6 xl:col-6">
                {/* Controles de abas */}
                <div className="layout-graph">
                    <div>
                        <Button
                            label="Performance"
                            className={`p-button-text ${activeTab === 'performance' ? 'active' : ''}`}
                            onClick={() => setActiveTab('performance')}
                        >

                        </Button>
                        <Button
                            label="Saúde"
                            className={`p-button-text ${activeTab === 'health' ? 'active' : ''}`}
                            onClick={() => setActiveTab('health')}
                        >

                        </Button>
                    </div>

                    {/* Conteúdo condicional com base na aba selecionada */}
                    {activeTab === 'performance' && (
                        <PerformanceGraph
                            date={props.date}
                            camera={props.camera}
                            lineOptions={props.lineOptions}
                            location={props.location}
                        />
                    )}
                    {activeTab === 'health' && (
                        <HealthGraph
                            date={props.date}
                            camera={props.camera}
                            lineOptions={props.lineOptions}
                            location={props.location}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};
