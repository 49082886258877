import React, { useEffect, useRef, useState } from "react";
import { MapContainer, TileLayer } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { HttpTransportType, HubConnection, HubConnectionBuilder } from "@microsoft/signalr";
import { environment } from "../../../configuration/environment";
import { isResponseFromSameCamera, isResponseFromSameLocation } from '../../../services/map-services';
import { blankPlateEventResponse, Camera, cardResponse, location, plateEventResponse } from "../types/types";

import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { DynamicMapCenter } from "./components/dynamic-map-center";
import VisibleCameras from "./components/visible-cameras";
import Legend from "./components/legend";
import LocateUserButton from "./components/locate-button";
import HeatmapLayer from "./components/heatmap-layer";
import 'leaflet.heat';
import { DashboardServices } from "../../../services/dashboard-services";

// Configurações de ícone padrão do Leaflet
L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
});


interface SignalRLiveMapProps {
    loading: boolean;
    cameras: Camera[];
    selectedLocation: number;
    listLocations: location[];
    selectedCamera: number;
    selecteDate: Date;
    intensityFactor: number;
    maxZoom: number;
    radius: number;
    blur: number;
    setSignalRConnected: (val: boolean) => void;
    onSelectedCamera: (val: any) => void;
    selectedVehicleTypes: (keyof cardResponse)[]; // Aqui indicamos que as opções são chaves de cardResponse
}

export const SignalRLiveMap = (props: SignalRLiveMapProps): JSX.Element => {
    const _dashboardServices = new DashboardServices();

    // Estados
    const [cameraEvents, setCameraEvents] = useState({});
    // const [listcard, setListcard] = useState<cardResponse[]>([]);
    const [readPlate, setReadPlate] = useState<plateEventResponse>(blankPlateEventResponse);
    const [selectedZoom, setSelectedZoom] = useState<number>(7);
    const [selectedLatitude, setSelectedLatitude] = useState<number>(-18.996449);
    const [selectedLongitude, setSelectedLongitude] = useState<number>(-44.444698);
    const [activePopupCameraId, setActivePopupCameraId] = useState<number | null>(null);
    const [vehicleCountsByCamera, setVehicleCountsByCamera] = useState<{ [cameraId: number]: cardResponse }>({});
    const [heatmapPoints, setHeatmapPoints] = useState<[number, number, number][]>([]);
    const [connection, setConnection] = useState<HubConnection | null>(null);

    // Refs para controle de estado
    const lastSelectedCamera = useRef<number>(props.selectedCamera);
    const lastSelectedLocation = useRef<number>(props.selectedLocation);
    const lastReadPlate = useRef<plateEventResponse>(blankPlateEventResponse);
    const lastConnection = useRef<HubConnection | null>(null);

    // Atualizar dados a cada 5 minutos
    useEffect(() => {
        updateCardData();
        const interval = setInterval(updateCardData, 300000); // 5 minutos
        return () => clearInterval(interval);
    }, [props.selecteDate, props.selectedCamera, props.selectedLocation]);

    // Ajustar centralização e zoom do mapa com base na localização/câmera selecionada
    useEffect(() => {
        adjustMapCenter();
    }, [props.selectedLocation, props.selectedCamera]);

    // Inicializar a conexão SignalR
    useEffect(() => {
        initializeSignalRConnection();
    }, []);

    // Iniciar a conexão SignalR
    useEffect(() => {
        startSignalRConnection();
    }, [connection]);

    // Atualizar os pontos do heatmap com base nas contagens de veículos
    useEffect(() => {
        generateHeatmapData();
    }, [vehicleCountsByCamera, props.cameras, props.selectedVehicleTypes, props.intensityFactor, props.radius, props.blur, props.maxZoom]);

    // Sincroniza o estado `readPlate` com a ref `lastReadPlate`
    useEffect(() => {
        lastReadPlate.current = readPlate;
    }, [readPlate]);

    // Funções auxiliares
    const adjustMapCenter = () => {
        if (props.selectedCamera) {
            const camera = props.cameras.find(cam => cam.id === props.selectedCamera);
            if (camera) {
                const [lat, lng] = adjustCoordinates(camera.numLatitude, camera.numLongitude);
                setSelectedLatitude(lat);
                setSelectedLongitude(lng);
                setSelectedZoom(17);
            }
        } else if (props.selectedLocation) {
            const location = props.listLocations.find(loc => loc.id === props.selectedLocation);
            if (location) {
                const [lat, lng] = adjustCoordinates(Number(location.latitude), Number(location.longitude));
                setSelectedLatitude(lat);
                setSelectedLongitude(lng);
                setSelectedZoom(11);
            }
        } else {
            setSelectedLatitude(-18.996449);
            setSelectedLongitude(-44.444698);
            setSelectedZoom(7);
        }
    };

    const initializeSignalRConnection = () => {
        if (!lastConnection.current) {
            const newConnection = new HubConnectionBuilder()
                .withUrl(environment().signalR + "live-connection", {
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets,
                })
                .withAutomaticReconnect()
                .build();
            setConnection(newConnection);
            lastConnection.current = newConnection;
        }
    };

    const startSignalRConnection = () => {
        if (connection) {
            connection.start()
                .then(() => {
                    console.log('Connected to SignalR hub');
                    props.setSignalRConnected(true);

                    connection.on('camera-report', (readPlateResponse: plateEventResponse) => {
                        handleReadPlate(readPlateResponse);
                    });
                })
                .catch((error) => console.error('SignalR Connection Error: ', error));
        }
    };

    const generateHeatmapData = () => {
        const heatmapData: [number, number, number][] = props.cameras.map((camera) => {
            const vehicleCounts = vehicleCountsByCamera[camera.id];

            if (vehicleCounts) {
                // Agora usamos diretamente os tipos selecionados em vez de fazer o mapeamento desnecessário
                const totalVehicles = props.selectedVehicleTypes.reduce((acc, vehicleType) => {
                    // Verifique se o valor mapeado existe em vehicleCounts e some ao total
                    return acc + (vehicleCounts[vehicleType] || 0);  // Já acessando diretamente os campos, como "quantityCar"
                }, 0);

                if (totalVehicles > 0) {
                    const intensity = Math.log(totalVehicles) / props.intensityFactor; // Aplicando o fator de intensidade

                    const [lat, lng] = adjustCoordinates(Number(camera.numLatitude), Number(camera.numLongitude));
                    return [lat, lng, intensity];
                }
            }
            return null;
        }).filter(Boolean) as [number, number, number][]; // Remove null values

        setHeatmapPoints(heatmapData);
    };


    const updateCardData = () => {
        _dashboardServices.getCardPerCameraData(props.selecteDate).then((response: any) => {
            if (response.status === 200) {
                const updatedVehicleCounts = response.data.reduce((acc: any, card: cardResponse) => {
                    acc[card.cameraId] = card;
                    return acc;
                }, {});
                setVehicleCountsByCamera(updatedVehicleCounts);
                // setListcard(response.data);
            } else {
                setVehicleCountsByCamera({});
                // setListcard([]);
            }
        });
    };

    const adjustCoordinates = (lat: number, lng: number): [number, number] => {
        return [lat > 0 ? -lat : lat, lng > 0 ? -lng : lng];
    };

    const handleReadPlate = (readPlateResponse: plateEventResponse) => {
        if (isResponseFromSameLocation(readPlateResponse.locationId, lastSelectedLocation.current) &&
            isResponseFromSameCamera(readPlateResponse.cameraId, lastSelectedCamera.current)) {
            const camera = props.cameras.find(cam => cam.id === readPlateResponse.cameraId);
            if (camera) {
                const [lat, lng] = adjustCoordinates(camera.numLatitude, camera.numLongitude);
                setReadPlate(readPlateResponse);
                setCameraEvents((prevEvents) => ({
                    ...prevEvents,
                    [camera.id]: readPlateResponse,
                }));

                setActivePopupCameraId(camera.id);
                setTimeout(() => setActivePopupCameraId(null), 3000);
            }
        }
    };

    if (props.loading) {
        return <div>Loading map...</div>;
    }

    return (
        <div className="map-wrapper">
            <MapContainer center={[selectedLatitude, selectedLongitude]} zoom={selectedZoom} className="leaflet-map-container">
                <TileLayer
                    attribution='&copy; OpenStreetMap contributors'
                    url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
                />

                {/* Heatmap Layer */}
                <HeatmapLayer
                    heatmapPoints={heatmapPoints}
                    radius={props.radius}   // Usa o valor ajustado pelo slide
                    blur={props.blur}       // Usa o valor ajustado pelo slide
                    maxZoom={props.maxZoom} // Usa o valor ajustado pelo slide
                />

                <LocateUserButton />

                <DynamicMapCenter latitude={selectedLatitude} longitude={selectedLongitude} zoom={selectedZoom} />

                <VisibleCameras
                    cameraEvents={cameraEvents}
                    readPlate={lastReadPlate.current}
                    cameras={props.cameras}
                    onSelectedCamera={props.onSelectedCamera}
                    activePopupCameraId={activePopupCameraId}
                    vehicleCountsByCamera={vehicleCountsByCamera}
                />

                <Legend
                intensityFactor={props.intensityFactor}
                blur={props.blur}
                radius={props.radius}
                maxZoom= {props.maxZoom}
                maxVehicles={1000}
                />
            </MapContainer>
        </div>
    );
};
