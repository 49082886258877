import React, { useEffect, useRef, useState } from "react";
import { DropdownInfo } from "../../../models/types";
import { AuthServices } from "../../../services/utils/auth-services";
import { CameraServices } from "../../../services/camera-services";
import { LocationServices } from "../../../services/location-services";
import { DropdownFilters } from "./filters/dropdown-filters";
import { TabGraph } from "./components/tab-graph";
import { SignalRLiveData } from "./signal-r-live-data";

export const Dashboard = (props: any) => {
    const today: Date = new Date();
    const _cameraServices = new CameraServices();
    const _locationServices = new LocationServices();
    const _authServices = new AuthServices();

    const [date, setDate] = useState<Date>(today);
    const [lineOptions, setLineOptions] = useState<any>(null);
    const [showPdfDialog, setShowPdfDialog] = useState<boolean>(false);
    const [selectedTimeSpan, setSelectedTimeSpan] = useState<number>(15);
    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [loadingLocations, setLoadingLocations] = useState<boolean>(true);
    const [loadingCameras, setLoadingCameras] = useState<boolean>(true);
    const [selectedCamera, setSelectedCamera] = useState<number>(0);
    const [camerasList, setCamerasList] = useState<DropdownInfo[]>([]);
    const [selectedLocation, setSelectedLocation] = useState<number>(0);
    const [signalRConnected, setSignalRConnected] = useState<boolean>(false);

    const lastSelectedCamera = useRef<number>(0);
    const lastSelectedLocation = useRef<number>(0);
    const lastSelectedDate = useRef<Date>(today);

    lastSelectedDate.current = date;
    lastSelectedCamera.current = selectedCamera;
    lastSelectedLocation.current = selectedLocation;

    _authServices.HandleAppAuth();

    useEffect(() => {
        getLocations(date);
    }, []);

    useEffect(() => {
        if (props.colorMode === "light") {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
    }, [props.colorMode]);

    const getLocations = (date: Date) => {
        setLoadingLocations(true);
        _locationServices.getActiveLocations(date)
            .then((response: any) => {
                let locations: DropdownInfo[] = [
                    { id: 0, description: "TODAS LOCALIDADES" }
                ];

                response.data.forEach((camera: any) => {
                    let locationResponse = {
                        description: camera.description,
                        id: camera.id
                    } as DropdownInfo;

                    locations.push(locationResponse);
                });
                setLocations(locations);
                setLoadingLocations(false);
            })
    }

    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#495057",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
                y: {
                    ticks: {
                        color: "#495057",
                    },
                    grid: {
                        color: "#ebedef",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    }

    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: "#ebedef",
                    },
                },
            },
            scales: {
                x: {
                    ticks: {
                        color: "#ebedef",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
                y: {
                    ticks: {
                        color: "#ebedef",
                    },
                    grid: {
                        color: "rgba(160, 167, 181, .3)",
                    },
                },
            },
        };

        setLineOptions(lineOptions);
    }

    const onSelectedUF = (e: { value: any }) => {
        setSelectedLocation(e.value);
        setLoadingCameras(true);
        let response: DropdownInfo[] = _cameraServices.getActiveCamerasByLocation(date, e.value);

        setSelectedCamera(0);
        setCamerasList(response);
        setLoadingCameras(false);
    }

    const onSelectedCamera = (e: { value: any }) => {
        setSelectedCamera(e.value);
    }

    const onSelectedTimeSpan = (e: { value: any }) => {
        setSelectedTimeSpan(e.value);
    }

    const onSelectedDate = (e: { value: any }) => {
        setDate(e.value);
        getLocations(date);
    }

    return (
        <div className="grid">
            <DropdownFilters
                date={date}
                today={today}
                cameras={camerasList}
                locations={locations}
                onSelectedUF={onSelectedUF}
                showPdfDialog={showPdfDialog}
                loadingCameras={loadingCameras}
                onSelectedDate={onSelectedDate}
                selectedCamera={selectedCamera}
                selectedTimeSpan={selectedTimeSpan}
                setShowPdfDialog={setShowPdfDialog}
                loadingLocations={loadingLocations}
                onSelectedCamera={onSelectedCamera}
                selectedLocation={selectedLocation}
                onSelectedTimeSpan={onSelectedTimeSpan}
                signalRConnected={signalRConnected}
            />

            <SignalRLiveData
                selecteDate={lastSelectedDate.current}
                selectedCamera={lastSelectedCamera.current}
                selectedLocation={lastSelectedLocation.current}
                setSignalRConnected={setSignalRConnected}
            />

            <TabGraph
                date={date}
                camera={selectedCamera}
                lineOptions={lineOptions}
                location={selectedLocation}
            />
        </div>
    )
};
