import classNames from 'classnames';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ClipLoader from "react-spinners/ClipLoader";
import { ToastServices } from '../../../services/utils/toast-services';
import { LoginServices } from '../../../services/login-services';
import { LoginInterface } from '../types/types';
import blackLogo from './../../../wwroot/img/moon-black-logo.png';
import whiteLogo from './../../../wwroot/img/moon-white-logo.png';
import moon from './../../../wwroot/img/moon.png';
import moon2 from './../../../wwroot/img/moon-2.png';
import './../style/login.scss';
import { SendPasswordRequestDialog } from './dialogs/send-password-request-dialog';

const Login = () => {
    var history = useHistory();
    const emptyLogin = {} as LoginInterface;
    const _loginService = new LoginServices();
    const _toastService = new ToastServices();
    const [loading, setLoading] = useState<boolean>(false);
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [user, setUser] = useState<LoginInterface>(emptyLogin);
    const [verificationCode, setVerificationCode] = useState<string>(""); // Estado para código de verificação
    const [resetPassordDialog, setResetPasswordDialog] = useState<boolean>(false);
    const [step, setStep] = useState(1); // 1: Login, 2: Verificação

    useEffect(() => {
        sessionStorage.clear();
    }, []);

    const onCheckedChange = (e: any, name: string) => {
        const val: boolean = (e.target && e.target.checked) || false;
        let _user = { ...user } as any;
        _user[name] = val;
        setUser(_user);
    }

    const onInputChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || "";
        let _user = { ...user } as any;
        _user[name] = val;
        setUser(_user);
    }

    // Modificar o onSubmit para incluir a lógica do passo 1 (login) e passo 2 (verificação)
    const onSubmit = () => {
        setSubmitted(true);

        if (step === 1) {
            if (_loginService.isUserValid(user)) {
                setLoading(true);
                _loginService.login(user)
                    .then((response) => {
                        setLoading(false);
                        if (response.data.accessToken) {
                            _loginService.saveInfo(response.data);
                            history.push('/');  // Login bem-sucedido, redirecionar para a página principal
                        } else {
                            setStep(2);  // Mudar para a etapa de verificação
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        _toastService.showErrorRequestMessage(error);
                    });
            }
        } else if (step === 2) {
            setLoading(true);
            _loginService.validateVerificationCode(user.username, verificationCode)
                .then((response) => {
                    _loginService.saveInfo(response.data);
                    history.push('/');  // Login bem-sucedido, redirecionar para a página principal
                })
                .catch((error) => {
                    setLoading(false);
                    _toastService.showErrorRequestMessage(error);
                });
        }
    }

    const getImageSource = () => {
        var userTheme = localStorage.getItem("user-theme") || 'claro';
        return userTheme === 'claro' ? blackLogo : whiteLogo;
    }

    const onForgotPassword = () => {
        setResetPasswordDialog(true);
    }
    const reSend = () => {
        setStep(1);
        onSubmit();
    }
    const onCloseDialog = () => {
        setResetPasswordDialog(false);
    }
    return (
        <div className='login-container'>
            <div className="borders start">
                <div className='image-container'>
                    <img className='image-moon' src={moon} alt="" />
                </div>
            </div>
            <div className="split">
                <div className="centered">
                    <div className='flex justify-content-center mb-4'>
                        <img className='login-image' src={getImageSource()} alt='olho-vivo-logo' />
                    </div>
                    <div className='login-card card'>
                        <div className='login-content'>
                            {step === 1 && (
                                <>
                                    <div className='login-field-group mb-4'>
                                        <strong className='login-field-description mb-2'>
                                            Usuário
                                        </strong>
                                        <InputText
                                            placeholder="Usuário"
                                            value={user.username}
                                            onChange={e => onInputChange(e, 'username')}
                                            type="text"
                                            required
                                            autoFocus
                                            className={'login-field ' + classNames({ 'p-invalid': submitted && !user.username })}
                                        />
                                    </div>
                                    <div className='login-field-group mb-4'>
                                        <strong className='login-field-description mb-2'>
                                            Senha
                                        </strong>
                                        <InputText
                                            placeholder="Senha"
                                            value={user.password}
                                            onChange={e => onInputChange(e, 'password')}
                                            type="password"
                                            required
                                            className={'login-field ' + classNames({ 'p-invalid': submitted && !user.password })}
                                        />
                                    </div>
                                    <div className='flex justify-content-between remember-password-group mb-4'>
                                        <div className='remember-password'>
                                            <Checkbox checked={user.rememberPassword} onChange={(e) => { onCheckedChange(e, 'rememberPassword') }} />
                                            <span className='ml-2'>Lembrar meu acesso</span>
                                        </div>
                                        <div className='forgot-password'>
                                            <a className="link-button" type='button' onClick={() => onForgotPassword()}>Esqueceu a senha?</a>
                                        </div>
                                    </div>
                                    <div className='login-field-group mb-2'>
                                        <Button
                                            label='Entrar'
                                            type='button'
                                            onClick={() => onSubmit()}
                                            className='login-field'
                                        />
                                    </div>
                                </>
                            )}
                            {step === 2 && (
                                <>
                                    <div className='flex justify-content-between remember-password-group login-field-description mb-4'>
                                        <strong>
                                            Código de verificação enviado para o email do usuário cadastrado
                                        </strong>
                                    </div>
                                    <div className='login-field-group mb-4'>
                                        <InputText
                                            placeholder="Código de verificação"
                                            value={verificationCode}
                                            onChange={e => setVerificationCode(e.target.value)}
                                            type="text"
                                            required
                                            autoFocus
                                            className={'login-field ' + classNames({ 'p-invalid': submitted && !verificationCode })}
                                        />

                                    </div>
                                    <div className='login-field-group mb-4'>
                                        <a className="link-button" type='button' onClick={() => reSend()}>Enviar código novamente</a>
                                    </div>
                                    <div className='login-field-group mb-2'>
                                        <Button
                                            label='Verificar'
                                            type='button'
                                            onClick={() => onSubmit()}
                                            className='login-field'
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='clip-loader-container'>
                        <ClipLoader color='var(--text-color)' loading={loading} size={25} />
                    </div>
                </div>
            </div>
            <div className="borders end">
                <div className='image-container-2'>
                    <img src={moon2} alt="" />
                </div>
            </div>
            <SendPasswordRequestDialog
                onCloseDialog={onCloseDialog}
                visible={resetPassordDialog}
            />
        </div>
    );
}

export default Login;
