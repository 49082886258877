import { Chart } from 'primereact/chart';
import React, { useEffect, useRef, useState } from 'react';
import { cameraHealth, cameraHealthGraph } from '../../../../models/types';
import '../../styles/health-graph.scss';
import { MonitoringServices } from '../../../../services/monitoring-services';

export interface HealthGraphProps {
    date: Date,
    location: number,
    camera: number,
    lineOptions: any
}

export const HealthGraph = (props: HealthGraphProps): JSX.Element => {
    const _monitoringServices = new MonitoringServices();


    const lastGraphData = useRef<cameraHealthGraph>();
    const [graphData, setGraphData] = useState<cameraHealthGraph>();

    lastGraphData.current = graphData;

    useEffect(() => {
        if (!props.location || props.location == 0) {
            loadCamerasUpdatedByLocations();
        } else {
            loadCamerasUpdatedByLocation();
        }
    }, [props.date, props.location, props.camera])

    const loadCamerasUpdatedByLocation = () => {
        _monitoringServices.getCameraRecent(props.location)
            .then((data: any) => {
                buildCamerasHealthGraph(data.data, props);
            })
    }

    const loadCamerasUpdatedByLocations = () => {
        _monitoringServices.getCameraRecentLocations()
            .then((data: any) => {
                buildCamerasHealthGraph(data.data, props);
            })
    }

    const buildCamerasHealthGraph = (result: cameraHealth[], props: HealthGraphProps) => {
        let labels: string[] = [];
        let dataSet: number[] = [];

        // Se `props.camera` estiver definido, filtra apenas os dados da câmera com o `cameraId` correspondente
        if (props.camera) {
            result = result.filter(camera => camera.cameraId === String(props.camera));
        }

        // Dicionário para armazenar as informações de cada câmera agrupada por cameraName
        const cameraByName: { [cameraName: string]: cameraHealth[] } = {};

        // Agrupa os dados por cameraName
        result.forEach((element) => {
            const cameraName = String(element.cameraName);

            if (!cameraByName[cameraName]) {
                cameraByName[cameraName] = [];
            }

            // Adiciona o elemento ao grupo correspondente ao cameraName
            cameraByName[cameraName].push(element);
        });

        // Dicionário para contar quantas vezes cada câmera ficou offline (apenas transições de online para offline)
        const offlineCameraTransitions: { [cameraName: string]: number } = {};

        // Verifica cada câmera e conta as transições de online para offline
        Object.keys(cameraByName).forEach(cameraName => {
            const cameraHistory = cameraByName[cameraName];

            // Ordena por timestamp se necessário (se não estiver já ordenado)
            cameraHistory.sort((a, b) => new Date(a.lastUpdated).getTime() - new Date(b.lastUpdated).getTime());

            // Itera sobre o histórico dessa câmera e conta as transições
            cameraHistory.forEach((element, index) => {
                if (index > 0) {
                    const previousElement = cameraHistory[index - 1];

                    // Verifica a transição de online para offline
                    if (previousElement.online === true && element.online === false) {
                        // Incrementa a contagem de transições para esta câmera
                        if (offlineCameraTransitions[cameraName]) {
                            offlineCameraTransitions[cameraName]++;
                        } else {
                            // Inicializa com 1 se for a primeira transição detectada
                            offlineCameraTransitions[cameraName] = 1;
                        }
                    }
                }
            });
        });

        // Transforma os dados do dicionário em listas para o gráfico
        Object.keys(offlineCameraTransitions).forEach(cameraName => {
            labels.push(cameraName); // Rótulos (cameraName)
            dataSet.push(offlineCameraTransitions[cameraName]); // Quantidades de transições
        });

        // Monta o objeto resposta para o gráfico
        let resposta: cameraHealthGraph = {
            labels: labels,
            datasets: [
                {
                    label: 'Transições para Offline em ' + props.date.toLocaleString("pt-br", {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric'
                    }),
                    backgroundColor: '#FF6384', // Cor para representar câmeras com transições offline
                    data: dataSet
                }
            ]
        };

        setGraphData(resposta);
    };



    return (
        <React.Fragment>
            <div className="card health-graph">
                <Chart
                    type="bar"
                    data={lastGraphData.current}
                    options={{
                        ...props.lineOptions,
                        responsive: true,
                        maintainAspectRatio: false // Permite que o gráfico se ajuste à altura definida
                    }}
                    style={{ minHeight: '100%' }} // O gráfico respeitará o tamanho da div pai
                />
            </div>
        </React.Fragment>
    );
}
