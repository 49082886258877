import React from "react"
import { Button } from "primereact/button"
import { Column } from "primereact/column"
import { Toolbar } from "primereact/toolbar"
import { DataTable } from "primereact/datatable"
import { InputText } from "primereact/inputtext"
import { dsmApplication } from "../../types/types"

export interface DSMApplicationListDataTableProps {
    dsmApplications: dsmApplication[];
    selectedDSMApplications: dsmApplication[];
    setSelectedDSMApplications: (selectedDSMApplications: dsmApplication[]) => void;
    loading: boolean;
    globalFilter: string;
    setGlobalFilter: (val: any) => void,
    editDSMApplication: (rowData: dsmApplication) => void;
    confirmDeleteDSMApplication: (rowData: dsmApplication) => void;
    confirmDeleteSelected: () => void,
    openNew: () => void
}

export const DSMApplicationListDataTable = (props: DSMApplicationListDataTableProps) => {

    const header = (
        <div className="table-header">
            <h5 className="p-m-2">Servidores DSM</h5>
        </div>
    )

    const getColor = (usingDDNS?: boolean) => {
        if (usingDDNS) {
            return '#00FF00'; // Verde fluorescente
        } else {
            return '#FF0000'; // Vermelho fluorescente
        }
    };

    const getStatus = (usingDDNS?: boolean) => {
        if (usingDDNS) {
            return 'SIM';
        } else {
            return 'NÃO';
        }
    };

    const typeBodyTemplate = (dsmApplication: dsmApplication) => {
        return <>{dsmApplication.type}</>;
    }

    const modoBodyTemplate = (dsmApplication: dsmApplication) => {
        return <>{dsmApplication.modo}</>;
    }

    const nameBodyTemplate = (dsmApplication: dsmApplication) => {
        return <>{dsmApplication.dsmApplicationName}</>;
    }

    const ipaddresBodyTemplate = (dsmApplication: dsmApplication) => {
        return <>{dsmApplication.ipAddress}</>;
    }

    const ddnsBodyTemplate = (dsmApplication: dsmApplication) => {
        return (
            <>
                <div style={{ color: getColor(dsmApplication.usingDDNS) }} className='dsm-monitor-justify'>{getStatus(dsmApplication.usingDDNS)}</div>
            </>
        );
    }

    const actionBodyTemplate = (rowData: dsmApplication) => {
        return (
            <div className="actions my-2 xl:offset-md-5">
                <Button icon="pi pi-pencil" className="p-button-rounded p-button mr-2" onClick={() => props.editDSMApplication(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-danger" onClick={() => props.confirmDeleteDSMApplication(rowData)} />
            </div>
        );
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <div className="my-2">
                    <Button label="Novo" icon="pi pi-plus" className="p-button-success mr-2" onClick={props.openNew} />
                    <Button label="Deletar" icon="pi pi-trash" className="p-button-danger" onClick={props.confirmDeleteSelected} disabled={!props.selectedDSMApplications || !props.selectedDSMApplications.length} />
                </div>
            </React.Fragment>
        );
    }

    const rightToolbarTemplate = () => {
        return (
            <>
                <React.Fragment>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            type="search"
                            value={props.globalFilter || ""}
                            onInput={(e: any) => props.setGlobalFilter(e.target.value)}
                            placeholder="Procurar..."
                        />
                    </span>
                </React.Fragment>
            </>
        );
    }

    return (
        <React.Fragment>
            <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
            <DataTable
                value={props.dsmApplications}
                selection={props.selectedDSMApplications}
                onSelectionChange={(e: any) => props.setSelectedDSMApplications(e.value as dsmApplication[])}
                dataKey="dsmApplicationId" // Certifique-se de que esta propriedade é única para cada item
                paginator
                rows={10}
                selectionMode='multiple'
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport"
                currentPageReportTemplate="Mostrando {first} de {last}, de um total de {totalRecords} servidores DSM"
                globalFilter={props.globalFilter || ""}
                emptyMessage="Nenhum servidor DSM encontrado."
                header={header}
                loading={props.loading}>
                <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                <Column field="dsmApplicationName" header="Nome" sortable body={nameBodyTemplate}></Column>
                <Column field="type" header="Tipo" sortable body={typeBodyTemplate}></Column>
                <Column field="modo" header="Modo" sortable body={modoBodyTemplate}></Column>
                <Column field="ipAddress" header="Endereço IP" sortable body={ipaddresBodyTemplate}></Column>
                <Column field="usingDDNS" header="DDNS" sortable body={ddnsBodyTemplate}></Column>
                <Column body={actionBodyTemplate}></Column>
            </DataTable>
        </React.Fragment>
    )
}
