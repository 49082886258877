import React, { useEffect, useState } from "react";
import { PageHeader } from "../../../views/page-header";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import classNames from 'classnames';
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { InputText } from 'primereact/inputtext';
import { Column } from "primereact/column";
import { VehicleDetectionServices } from "../../../services/vehicle-detection-services";
import { DropdownInfo } from "../../../models/types";
import { LocationServices } from "../../../services/location-services";
import { vehicleType } from '../../../shared/constants/vehicle-type';
import { vehicleColor } from '../../../shared/constants/vehicle-color';
import { ClipLoader } from "react-spinners";
import { translateColor, untranslateColor } from "../../../services/utils/input-services";
import { VehicleDetectionListDataTable } from "../data-tables/vehicle-detection-list-data-table";

export const ReportHistory = (props: any) => {

    const _vehicleDetectionServices = new VehicleDetectionServices();
    const _locationServices = new LocationServices();

    const [cameras, setCameras] = useState<DropdownInfo[]>([]);
    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [selectedVehicleType, setSelectedVehicleType] = useState<vehicleType | null>(null);
    const [selectedVehicleColor, setSelectedVehicleColor] = useState<vehicleColor | null>(null);
    const [selectedPlate, setSelectedPlate] = useState<string>("");
    const [cameraId, setCameraId] = useState<number | null>(null);
    const [locationId, setLocationId] = useState<number | null>(null);
    const [initialDate, setInitialDate] = useState<Date | null>(null);
    const [finalDate, setFinalDate] = useState<Date | null>(null);
    const [vehicleHistory, setVehicleHistory] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);


    useEffect(() => {
        getLocations();
        getCameras();
    }, []);

    useEffect(() => {
        if (locationId) {
            // Quando o locationId muda, chama a função para carregar câmeras filtradas pela localidade
            getCamerasByLocation(locationId);
        }
    }, [locationId]);

    // Gera as opções para o Dropdown a partir do enum
    const vehicleOptions = [
        { description: "TODOS OS VEÍCULOS", id: 0 },  // Adiciona a opção "TODOS OS VEÍCULOS" com id 0
        ...Object.entries(vehicleType)
            .filter(([key, value]) => typeof value === "number")  // Filtra apenas as entradas numéricas
            .map(([key, value], index) => ({
                description: key,    // Nome do veículo (CARRO, MOTO, etc.)
                id: index + 1  // Ajusta o ID para começar em 1, já que 0 é "TODOS OS VEÍCULOS"
            }))
    ];


    const vehicleOptionsColor = [
        { description: "TODAS AS CORES", id: 0 },  // Mantém o ID 0 para "TODAS AS CORES"
        ...Object.entries(vehicleColor)
            .filter(([key, value]) => typeof value === "number")  // Filtra apenas as entradas numéricas
            .map(([key, value], index) => ({
                description: key,  // Nome da cor
                id: index + 1  // Ajusta o ID para começar em 1, já que 0 é "TODAS AS CORES"
            }))
    ];


    const getLocations = () => {
        setLoading(true);
        _locationServices.getActiveLocationsDataAnalysis().then((response: any) => {
            // Mantém as propriedades id e description, como estava funcionando antes
            let locations: DropdownInfo[] = [{ description: "TODAS LOCALIDADES", id: 0 }];

            response.data.forEach((location: any) => {
                locations.push({
                    description: location.description,  // Continua usando "description"
                    id: location.id,  // Continua usando "id"
                });
            });

            setLocations(locations);  // Atualiza o estado com as localizações no formato correto
            setLoading(false);
        });
    };

    // Função para obter câmeras com base no locationId selecionado
    const getCamerasByLocation = (locationId: number | null) => {
        setLoading(true);
        _vehicleDetectionServices.getCamerasByLocationDataAnalysis(locationId)
            .then((response) => {
                let cameras: DropdownInfo[] = [{ description: "TODAS CÂMERAS", id: 0 }];
                response.data.forEach((camera: DropdownInfo) => {
                    cameras.push({
                        description: camera.description,
                        id: camera.id,
                    });
                });
                setCameras(cameras); // Atualiza as câmeras filtradas
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };


    const getCameras = () => {
        setLoading(true);
        _vehicleDetectionServices.getCameras()
            .then((response) => {
                let cameras: DropdownInfo[] = [{ description: "TODAS CAMERAS", id: 0 }];
                response.data.forEach((camera: DropdownInfo) => {
                    cameras.push({
                        description: camera.description,
                        id: camera.id,
                    });
                });

                setCameras(cameras);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    };


    // Função para buscar o histórico de veículos com base nos filtros
    const searchVehicleHistory = async () => {
        setLoading(true);
        try {
            // Converte o valor de selectedVehicleColor usando untranslateColor, se houver cor selecionada
            const vehicleColor = selectedVehicleColor !== null && selectedVehicleColor !== 0
                ? untranslateColor(vehicleOptionsColor.find(option => option.id === selectedVehicleColor)?.description || "")
                : null;

            // Converte o valor de selectedVehicleType, se houver tipo de veículo selecionado
            const vehicleType = selectedVehicleType !== null && selectedVehicleType !== 0
                ? vehicleOptions.find(option => option.id === selectedVehicleType)?.description || ""
                : null;
            const response = await _vehicleDetectionServices.getVehicleHistory(cameraId, locationId, vehicleType, vehicleColor, selectedPlate, initialDate, finalDate);
            setVehicleHistory(response.data);
        } catch (error) {
            console.error("Erro ao buscar histórico de veículos", error);
        }
        setLoading(false);
    };

    const isOlderDateSelectedFinal = (selectedDate: Date): boolean => {
        if (finalDate) {
            let dateDay: number = selectedDate.getDate();
            let finalDay: number = finalDate.getDate();

            let dateMonth: number = selectedDate.getMonth();
            let finalMonth: number = finalDate.getMonth();

            let dateYear: number = selectedDate.getFullYear();
            let finalYear: number = finalDate.getFullYear();

            return dateDay > finalDay || dateMonth > finalMonth || dateYear > finalYear;
        } else {
            return true;
        }
    };

    const isNewerDateSelectedInitial = (selectedDate: Date): boolean => {
        if (initialDate != null) {
            let dateDay: number = selectedDate.getDate();
            let initialDay: number = initialDate.getDate();

            let dateMonth: number = selectedDate.getMonth();
            let initialMonth: number = initialDate.getMonth();

            let dateYear: number = selectedDate.getFullYear();
            let initialYear: number = initialDate.getFullYear();
            return dateDay < initialDay || dateMonth < initialMonth || dateYear < initialYear;
        } else {
            return false;
        }
    };


    return (
        <React.Fragment>
            <div className="grid">
                <div className="col-12">
                    <PageHeader
                        title="HISTÓRICO DE VEÍCULOS COM FILTROS"
                        icon="pi pi-car"
                    />
                    <span className="titulo-categoria">CARACTERÍSTICAS E LOCALIZAÇÃO:</span>
                    <div className="categoria">
                        <div className="card">
                            <div className="grid p-fluid">
                                <div className="col-12 md:col-6 lg:col-2">
                                    <label htmlFor="locationId">Localidade</label>
                                    <Dropdown
                                        id="locationId"
                                        value={locationId}
                                        options={locations}
                                        disabled={loading || loading || loading}
                                        onChange={(e) => setLocationId(e.value)}
                                        placeholder="Selecione a Localidade"
                                        optionLabel="description"  // Especifica que "description" deve ser usado como o rótulo da opção
                                        optionValue="id"  // Especifica que "id" deve ser usado como o valor da opção
                                    />

                                </div>
                                <div className="col-12 md:col-6 lg:col-2">
                                    <label htmlFor="cameraId">Câmera</label>
                                    <Dropdown
                                        id="cameraId"
                                        value={cameraId}
                                        options={cameras}
                                        disabled={loading || loading || loading}
                                        onChange={(e) => setCameraId(e.value)}
                                        placeholder="Selecione a Câmera"
                                        optionLabel="description"  // Especifica que "description" deve ser usado como o rótulo da opção
                                        optionValue="id"  // Especifica que "id" deve ser usado como o valor da opção
                                    />
                                </div>

                                <div className="col-12 md:col-6 lg:col-2">
                                    <label htmlFor="vehicleType">Tipo</label>
                                    <Dropdown
                                        id="vehicleType"
                                        value={selectedVehicleType}  // Estado que mantém o valor selecionado
                                        options={vehicleOptions}     // As opções do Dropdown no formato { label, value }
                                        disabled={loading || loading || loading}
                                        onChange={(e) => setSelectedVehicleType(e.value)}  // Atualiza o estado ao mudar a seleção
                                        placeholder="Selecione o tipo de veículo"
                                        optionLabel="description"  // Especifica que "description" deve ser usado como o rótulo da opção
                                        optionValue="id"  // Especifica que "id" deve ser usado como o valor da opção
                                    />
                                </div>
                                <div className="col-12 md:col-6 lg:col-2">
                                    <label htmlFor="plate">Placa</label>
                                    <InputText
                                        id="plate"
                                        value={selectedPlate}  // Estado para a placa
                                        onChange={(e) => setSelectedPlate(e.target.value)}  // Atualiza o valor da placa
                                        placeholder="Digite a placa do veículo"
                                    />
                                </div>
                                <div className="col-12 md:col-6 lg:col-2">
                                    <label htmlFor="vehicleColor">Cor</label>
                                    <Dropdown
                                        id="vehicleColor"
                                        value={selectedVehicleColor}  // Estado que mantém o valor selecionado
                                        options={vehicleOptionsColor}     // As opções do Dropdown no formato { label, value }
                                        disabled={loading || loading || loading}
                                        onChange={(e) => setSelectedVehicleColor(e.value)}  // Atualiza o estado ao mudar a seleção
                                        placeholder="Selecione a cor do veículo"
                                        optionLabel="description"  // Especifica que "description" deve ser usado como o rótulo da opção
                                        optionValue="id"  // Especifica que "id" deve ser usado como o valor da opção
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <span className="titulo-categoria">TEMPORIZAÇÂO:</span>
                    <div className="categoria">
                        <div className="card">
                            <div className="grid p-fluid">
                                <div className="col-12 md:col-6 lg:col-3">
                                    <label htmlFor="initialDate">Data de Início</label>
                                    <Calendar
                                        id="initialDate"
                                        value={initialDate}
                                        onChange={(e: any) => setInitialDate(e.value)}
                                        disabled={loading || loading || loading}
                                        showIcon
                                        placeholder="Selecione a Data de Início menor que a Data de Fim"
                                        dateFormat="dd/mm/yy"
                                        className={classNames({ "p-invalid": initialDate && finalDate != null && isOlderDateSelectedFinal(finalDate) })}
                                    />
                                </div>

                                <div className="col-12 md:col-6 lg:col-3">
                                    <label htmlFor="finalDate">Data de Fim</label>
                                    <Calendar
                                        id="finalDate"
                                        value={finalDate}
                                        onChange={(e: any) => setFinalDate(e.value)}
                                        disabled={loading || loading || loading}
                                        showIcon
                                        placeholder="Selecione a Data de Fim maior que a Data de Início"
                                        dateFormat="dd/mm/yy"
                                        // className={classNames({ "p-invalid": finalDate && initialDate != null && isNewerDateSelectedInitial(initialDate) })}
                                        className={classNames({ "p-invalid": initialDate != null && isNewerDateSelectedInitial(initialDate) })}
                                    />
                                </div>


                            </div>



                        </div>
                    </div>
                    <div className="categoria">
                        <span className="titulo-categoria">BUSCA:</span>
                        <div className="grid p-fluid">
                            <div className="col-12 md:col-6 lg:col-12">
                                <div className="col-12 md:col-6 lg:col-2">
                                    <Button
                                        label="Buscar Histórico"
                                        icon="pi pi-search"
                                        onClick={searchVehicleHistory}
                                        disabled={loading || loading || loading}
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <VehicleDetectionListDataTable
                        setGlobalFilter={props.setGlobalFilter}
                        vehicleHistory={vehicleHistory}
                        loading={props.loading}
                        globalFilter={props.globalFilter}
                    />

                </div>
                <div className='col-1'>
                    <ClipLoader color='var(--text-color)' loading={loading} size={25} />
                </div>
            </div>
        </React.Fragment >
    );
};
