import { DropdownInfo } from "../../../models/types";

export interface cardResponse {
    cameraId: number,
    totalFailure: number,
    totalIntegrations: number,
    totalRead: number,
    quantityCar: number,
    quantityMotorBike: number,
    quantityTruck: number,
    quantityBus: number,
    quantityPickup: number,
    quantityOther: number,
}

export interface Camera {
    id: number;
    dscCamera: string;
    nomUF: string;
    nomLocalidade: string;
    dscUsuario: string;
    dscSenha: string;
    dscEndereco: string;
    numLatitude: number;
    numLongitude: number;
    dscUrl: string;
    numPorta: number;
    numImageMode: number;
    idLocalidade: number;
    velocidadeMaxima: number;
    nomCameraModelo: string;
    idCameraModelo: number;
    indAtivo: boolean;
    indSignalR: boolean;
    // vehicleCount?: number;
    // integrations: cameraIntegration[];
}

export interface integrationCardResponse {
    totalRead: number,
    locationId: number,
    cameraId: number,
    totalFailure: number,
    totalSuccess: number,
    activeCameras: number,
    locationCount: number,
    inactiveCameras: number,
    isSuccess: boolean
}


export interface plateEventResponse {
    camera: string,
    captureTime: string,
    plate: string,
    integrations: integrationMessageResponse[],
    isSucess: boolean,
    cameraId: number,
    locationId: number,
    vehicleType: string,
    vehicleBrand: string,
    vehicleColor: string,
    vehicleSpeed: number,
    cameraMaxSpeed: number,
    vehicleImageBytes: string
}

export const blankPlateEventResponse = {
    camera: '',
    captureTime: '',
    plate: '',
    integrations: [] as integrationMessageResponse[],
    isSucess: false,
    cameraId: 0,
    locationId: 0,
    vehicleType: '',
    vehicleBrand: '',
    vehicleColor: '',
    vehicleSpeed: 0,
    cameraMaxSpeed: 0,
    vehicleImageBytes: ''
}

export interface integrationMessageResponse {
    name: string,
    success: boolean
}

export interface integrationCardResponse {
    totalRead: number,
    locationId: number,
    cameraId: number,
    totalFailure: number,
    totalSuccess: number,
    activeCameras: number,
    locationCount: number,
    inactiveCameras: number,
    isSuccess: boolean
}

export const blankIntegrationCard = {
    totalRead: 0,
    totalFailure: 0,
    totalSuccess: 0,
    activeCameras: 0,
    inactiveCameras: 0,
    locationCount: 0,
    locationId: 0
} as integrationCardResponse;

export interface location {
    id: number,
    latitude: string,
    longitude: string
}

export const emptyLocation: location = {
    id: 0,
    latitude: '',
    longitude: ''
};


export interface DropdownFiltersProps {
    date: Date,
    today: Date,
    showPdfDialog: boolean,
    loadingCameras: boolean,
    loadingLocations: boolean,
    selectedLocation: number,
    cameras: DropdownInfo[],
    locations: DropdownInfo[],
    selectedCamera: number,
    selectedTimeSpan: number,
    selectedVehicleTypes: string[],  // Aqui definimos os tipos de veículos selecionados
    setShowPdfDialog: (e: any) => void,
    onSelectedLocation: (e: any) => void,
    onSelectedDate: (e: any) => void,
    onSelectedCamera: (e: any) => void,
    onSelectedTimeSpan: (e: any) => void,
    onSelectedVehicleTypes: (e: any) => void,  // Adicionamos essa função
    signalRConnected: boolean
}
