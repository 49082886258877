import axios from 'axios';
import { environment } from "../configuration/environment";
import { whatsappRecipient } from '../pages/Whatsapp/types/types';
import { whatsappGroup } from '../pages/Whatsapp/types/types';

export class WhatsAppServices {
    getWhatsappGroupsByLocation(locationId: number) {
        return axios.get(environment().baseUrl + environment().version + `/whatsapp/whatsapp-group/${locationId}`, {
            headers: {
                'Authorization': sessionStorage.getItem('Bearer')
            }
        })
    }

    getWhatsappGroupById(groupId: number) {
        return axios.get(environment().baseUrl + environment().version + `/whatsapp/whatsapp-group/group/${groupId}`, {
            headers: {
                'Authorization': sessionStorage.getItem('Bearer')
            }
        })
    }

    getWhatsappRecipientsByGroup(groupId: number) {
        return axios.get(environment().baseUrl + environment().version + `/whatsapp/recipients-by-group`, {
            params: {
                groupId
            },
            headers: {
                'Authorization': sessionStorage.getItem('Bearer')
            }
        }
        );
    }

    createWhatsappRecipient(recipient: whatsappRecipient, locationId: number) {
        return axios.post(environment().baseUrl + environment().version + '/whatsapp', {
            locationId: locationId,
            recipientName: recipient.recipientName,
            recipientNumber: recipient.recipientCode,
            whatsappGroupId: recipient.whatsappGroupId,
            whatsappGroupName: recipient.whatsappGroupName
        }, {
            headers: {
                'Authorization': sessionStorage.getItem('Bearer')
            }
        })
    }

    updateWhatsappRecipient(recipient: whatsappRecipient) {
        return axios.put(environment().baseUrl + environment().version + '/whatsapp/' + recipient.recipientId, {
            active: recipient.active,
            recipientName: recipient.recipientName,
            recipientNumber: recipient.recipientCode,
            whatsappGroupId: recipient.whatsappGroupId,
            whatsappGroupName: recipient.whatsappGroupName
        }, {
            headers: {
                'Authorization': sessionStorage.getItem('Bearer')
            }
        });
    }

    updateWhatsappGroup(group: whatsappGroup) {
        return axios.put(environment().baseUrl + environment().version + '/whatsapp/group/' + group.locationWhatsappGroupId, {
            groupName: group.groupName
        }, {
            headers: {
                'Authorization': sessionStorage.getItem('Bearer')
            }
        });
    }

    deleteWhatsappRecipient(recipientId: number) {
        return axios.delete(environment().baseUrl + environment().version + '/whatsapp/' + recipientId, {
            headers: {
                'Authorization': sessionStorage.getItem('Bearer')
            }
        });
    }

    deleteWhatsappGroup(locationWhatsappGroupId: number) {
        return axios.delete(environment().baseUrl + environment().version + '/whatsapp/group/' + locationWhatsappGroupId, {
            headers: {
                'Authorization': sessionStorage.getItem('Bearer')
            }
        });
    }
}
