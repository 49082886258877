import classNames from "classnames";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import React, { useState } from "react";
import { onCheckedChange, onInputChange } from "../../../../services/utils/input-services";
import { user } from "../../../Login/types/types";
import "./../../styles/user.scss";

export interface EditUserDialogProps {
    user: user;
    setUser: (user: user) => void;
    setUserDialog: (showUserDialog: boolean) => void;
    isEditingUser: boolean;
    visible: boolean;
    submitted: boolean;
    setSubmitted: (value: boolean) => void;
    onSubmit: () => void;
    hideDialog: () => void;
    dialogHeader: string;
    passwordError: string | null
}

export const EditUserDialog = (props: EditUserDialogProps) => {

    const userDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={props.hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={(e) => props.onSubmit()} />
        </>
    )

    return (
        <div className="p-grid crud-demo">
            <div className="card">
                <Dialog visible={props.visible} style={{ width: "450px" }} header={props.dialogHeader} modal className="p-fluid" footer={userDialogFooter} onHide={props.hideDialog}>
                    <span className="titulo-categoria">IDENTIFICAÇÃO:</span>
                    <div className="categoria">
                        <div className="grid">
                            <div className="p-field col-6">
                                <span>Nome*</span>
                                <InputText
                                    disabled
                                    id="name"
                                    type="text"
                                    value={props.user.userName}
                                    onChange={(e) => onInputChange(e, "userName", props.user, props.setUser)}
                                    required
                                    autoFocus
                                    className={classNames({ "p-invalid": props.submitted && !props.user.userName })}
                                />
                                {props.submitted && !props.user.userName && <small className="p-invalid">Insira um Nome.</small>}
                            </div>
                            <div className="p-field col-6">
                                <span>Email*</span>
                                <InputText
                                    id="email"
                                    type="text"
                                    value={props.user.email}
                                    onChange={(e) => onInputChange(e, "email", props.user, props.setUser)}
                                    required
                                    autoFocus
                                    className={classNames({ "p-invalid": props.submitted && !props.user.email })}
                                />
                                {props.submitted && !props.user.email && <small className="p-invalid">Insira um Email.</small>}
                            </div>
                        </div>
                    </div>

                    <span className="titulo-categoria">CREDENCIAIS:</span>
                    <div className="categoria">
                        <div className="formgrid grid">
                            <div className="field col-6">
                                <span>Acesso*</span>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-user"></i>
                                    </span>
                                    <InputText
                                        id="access"
                                        type="text"
                                        value={props.user.userAccess}
                                        onChange={(e) => onInputChange(e, "userAccess", props.user, props.setUser)}
                                        required
                                        autoFocus
                                        className={classNames({ "p-invalid": props.submitted && !props.user.userAccess })}
                                    />
                                </div>
                                {props.submitted && !props.user.userAccess && (props.user.newpassword !== "" || props.user.newpassword !== null) && <small className="p-invalid">Insira um Usuário.</small>}
                            </div>
                            <div className="field col-6">
                                <span>Senha*</span>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-lock"></i>
                                    </span>
                                    <Password
                                        value={props.user.password}
                                        toggleMask
                                        feedback={false}
                                        onChange={(e) => onInputChange(e, "password", props.user, props.setUser)}
                                        required
                                        autoFocus
                                        className={classNames({ "p-invalid": props.submitted && !props.user.password })}
                                    />
                                </div>
                                {props.submitted && !props.user.password && (props.user.newpassword !== "" || props.user.newpassword !== null) && <small className="p-invalid">Insira uma senha.</small>}
                            </div>

                            <div className="field col-6">
                                <span>Nova senha*</span>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-lock"></i>
                                    </span>
                                    <Password
                                        value={props.user.newpassword}
                                        toggleMask
                                        feedback={false}
                                        onChange={(e) => onInputChange(e, "newpassword", props.user, props.setUser)}
                                        required
                                        autoFocus
                                        className={classNames({ "p-invalid": props.submitted && !!props.user.newpassword })}
                                    />
                                </div>
                                {props.submitted && !props.user.newpassword && <small className="p-invalid">Insira uma nova senha.</small>}
                            </div>

                            <div className="field col-6">
                                <span>Confirme a Senha*</span>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-lock"></i>
                                    </span>
                                    <Password
                                        value={props.user.repetpassword}
                                        toggleMask
                                        feedback={false}
                                        onChange={(e) => onInputChange(e, "repetpassword", props.user, props.setUser)}
                                        required
                                        autoFocus
                                        className={classNames({ "p-invalid": props.submitted && !props.user.repetpassword })}
                                    />
                                </div>
                                {props.submitted && !props.user.repetpassword && (props.user.newpassword !== "" || props.user.newpassword !== null) && <small className="p-invalid">Confirme a senha.</small>}
                                {props.passwordError && <small className="p-invalid">{props.passwordError}</small>} {/* Exibe erro se as senhas não coincidirem */}
                            </div>
                        </div>
                    </div>

                    <span className="titulo-categoria">TIPO DE LOGIN:</span>
                    <div className="categoria">
                        <div className="p-field-checkbox">
                            <Checkbox
                                className="mr-2"
                                inputId="isAdministrator"
                                name="option"
                                checked={props.user.isMultifactor}
                                onChange={(e) => onCheckedChange(e, "isMultifactor", props.user, props.setUser)}
                            />
                            <span>Autenticação Multifator</span>
                        </div>
                    </div>
                </Dialog>
            </div>
        </div>
    )
};
