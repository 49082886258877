import React, { Fragment } from 'react';
import { Camera, plateEventResponse } from "../../types/types";
import { translateColor } from '../../../../services/utils/input-services';

interface CameraCardProps {
    camera: Camera;
    readPlate: plateEventResponse | null;
    vehicleCount: {
        quantityCar: number;
        quantityMotorBike: number;
        quantityTruck: number;
        quantityBus: number;
        quantityPickup: number;
        quantityOther: number;
    };
}

const CameraCard: React.FC<CameraCardProps> = React.memo((props: CameraCardProps) => {
    return (
        <Fragment>
            <div className="camera-card-container">
                <h3 className="camera-title">{props.camera.dscCamera}</h3>
                <div className="content-sections">
                    <div className="left-section">
                        {props.readPlate && (
                            <>
                                {/* Exibir a imagem capturada do veículo */}
                                {props.readPlate.vehicleImageBytes && (
                                    <div className="vehicle-image-container">
                                        <img
                                            src={`data:image/jpeg;base64,${props.readPlate.vehicleImageBytes}`}
                                            alt="Imagem do veículo"
                                        />
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <div className="mid-section">
                        {props.readPlate && (
                            <>
                                <h1>Placa: {props.readPlate.plate}</h1>
                                <div>
                                    {props.readPlate.vehicleType !== "Other" && props.readPlate.vehicleType !== "Outro" && (
                                        <div>
                                            {props.readPlate.vehicleType}
                                        </div>
                                    )}
                                </div>

                                <div>
                                    {props.readPlate.vehicleBrand !== "Other" && (
                                        <div>
                                            {props.readPlate.vehicleBrand}
                                        </div>
                                    )}
                                </div>
                                <div>
                                    {props.readPlate.vehicleBrand !== "Other" && (
                                        <div>
                                            <i style={{
                                                backgroundColor: props.readPlate.vehicleColor.toLowerCase(),
                                                width: '20px',
                                                height: '20px',
                                                display: 'inline-block',
                                                marginRight: '10px'
                                            }}></i>
                                            <div>{translateColor(props.readPlate.vehicleColor)}</div>
                                        </div>
                                    )}
                                </div>
                                <div>{props.readPlate.captureTime}</div>

                            </>
                        )}
                    </div>

                    <div className="right-section">
                        <h4>Contagem de Veículos</h4>
                        <div>Carros: {props.vehicleCount?.quantityCar || 0}</div>
                        <div>Motos: {props.vehicleCount?.quantityMotorBike || 0}</div>
                        <div>Caminhões: {props.vehicleCount?.quantityTruck || 0}</div>
                        <div>Ônibus: {props.vehicleCount?.quantityBus || 0}</div>
                        <div>Pickups: {props.vehicleCount?.quantityPickup || 0}</div>
                        <div>Outros: {props.vehicleCount?.quantityOther || 0}</div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
});

export default CameraCard;
