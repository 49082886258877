import { useEffect } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet.heat";  // Importar biblioteca do Leaflet para Heatmap

interface HeatmapLayerProps {
    heatmapPoints: [number, number, number][];  // [latitude, longitude, intensidade]
    radius: number;
    blur: number;
    maxZoom: number;
}

const HeatmapLayer: React.FC<HeatmapLayerProps> = ( props: HeatmapLayerProps) => {
    const map = useMap();  // Hook para acessar o mapa

    useEffect(() => {

        if (props.heatmapPoints.length > 0) {
            // Cria o heatLayer
            const heatLayer = L.heatLayer(props.heatmapPoints, {
                radius: props.radius,   // Ajuste o raio para algo razoável como 25 ou 50
                blur: props.blur,     // Suavidade dos pontos
                maxZoom: props.maxZoom   // Zoom máximo para exibir o heatmap
            });

            // Adiciona o heatLayer ao mapa
            heatLayer.addTo(map);


            return () => {
                map.removeLayer(heatLayer);  // Remove a camada quando o componente for desmontado
            };
        }
    }, [props.heatmapPoints, map]);

    return null;
};

export default HeatmapLayer;
