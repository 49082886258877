import classNames from "classnames";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { WhatsappGroup } from "../../../components/WhatsappGroup/whatsapp-group";
import { DropdownInfo } from "../../../models/types";
import { CameraServices } from "../../../services/camera-services";
import { FeatureServices } from "../../../services/feature-services";
import { AuthServices } from "../../../services/utils/auth-services";
import { getNumberOfPassengers, getVehicleBrands, getVehicleColors, getVehicleTypes } from "../../../shared/constants-dropdown/vehicle-constants-dropdown";
import { customAlarmInterface } from "../types/types";
import { InputText } from "primereact/inputtext";
import { onInputChange } from "../../../services/utils/input-services";

export interface CreateCustomAlarmDialogProps {
    customAlarm: customAlarmInterface;
    setCustomAlarm: (customAlarm: customAlarmInterface) => void;
    setCustomAlarmDialog: (showCustomAlarmDialog: boolean) => void;
    isEditingCustomAlarm: boolean;
    visible: boolean;
    submitted: boolean;
    setSubmitted: (value: boolean) => void;
    onSubmit: () => void;
    hideDialog: () => void;
    dialogHeader: string;
    setLoading: (isLoading: boolean) => void;
    hasLessThanTwoParametersSelected: () => boolean;
}
export const CreateCustomAlarmDialog = (props: CreateCustomAlarmDialogProps) => {
    const [cameras, setCameras] = useState<DropdownInfo[]>([]);
    const [locations, setLocations] = useState<DropdownInfo[]>([]);
    const [loadingLocations, setLoadingLocations] = useState<boolean>(true);
    const [loadingCameras, setLoadingCameras] = useState<boolean>(false);
    const _cameraServices = new CameraServices();
    const _featureServices = new FeatureServices();
    const _authServices = new AuthServices();

    const [isNameValid, setIsNameValid] = useState(false);

    _authServices.HandleAppAuth();

    useEffect(() => {
        getLocations();
    }, [])

    const getLocations = () => {
        setLoadingLocations(true);
        _featureServices.getLocationsByFeature('CUSTOM-ALARM')
            .then(data => {
                var response: DropdownInfo[] = data.data;
                setLocations(response);
                setLoadingLocations(false);
                if (response.at(0)) {
                    var responseAt: number = response.at(0).id;
                    onSelectedCameraLocation(responseAt);
                }
            })
    }

    const onSelectedCameraLocation = (locationId: number) => {
        setLoadingCameras(true);

        let response: DropdownInfo[] = _cameraServices.getCamerasByLocation(locationId);

        if (props.isEditingCustomAlarm) {
            removeAllCamerasOptionFromDropdown(response);
        }
        setCameras(response)
        setLoadingCameras(false);
    }

    const removeAllCamerasOptionFromDropdown = (dropdown: DropdownInfo[]) => {
        const index = dropdown.findIndex(x => x.id === 0);

        if (index > -1) {
            dropdown.splice(index, 1);
        }
    }

    const hideDialog = () => {
        props.setLoading(true);
        props.hideDialog()
    }

    const saveCustomAlarm = () => {
        props.setLoading(true);
        if (props.customAlarm.camera.id === 0) {
            cameras.forEach(camera => {
                props.customAlarm.camera.id = camera.id
                props.setCustomAlarm(props.customAlarm);
                props.onSubmit();
            });

            return;
        }

        props.onSubmit()
    }

    const customAlarmDialogFooter = (
        <>
            <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Salvar" icon="pi pi-check" className="p-button-text" onClick={saveCustomAlarm} />
        </>
    )

    const onCheckedChange = (e: any, name: string) => {
        const val: boolean = (e.target && e.target.checked) || false;

        let customAlarmProperties = { ...props.customAlarm } as any;
        customAlarmProperties[name] = val;
        props.setCustomAlarm(customAlarmProperties);
    }

    const onSelectedCamera = (e: any, property: string) => {
        const val = (e.target && e.target.value) || 0;
        onDropdownValueChange(val, property);
    }

    const onDropdownValueChange = (val: any, name: string) => {
        let customAlarm = { ...props.customAlarm } as any;

        var newLocation: DropdownInfo = {
            description: '',
            id: val
        };

        customAlarm[`${name}`] = newLocation;

        props.setCustomAlarm(customAlarm);
    }

    const onVehicleDropdownValueChange = (val: any, name: string) => {
        let customAlarm = { ...props.customAlarm } as any;

        customAlarm[`${name}`] = val.target.value;

        props.setCustomAlarm(customAlarm);
    }

    const onLocationChange = (e: any, name: string) => {
        const val = (e.target && e.target.value) || "";
        onDropdownValueChange(val, name);
        onSelectedCameraLocation(val);
    }

    const validateName = (name: string): boolean => {
        const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ]+(\s[A-Za-zÀ-ÖØ-öø-ÿ]+)+$/;
        return nameRegex.test(name) && !name.includes('@');
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setIsNameValid(validateName(value));
        onInputChange(e, 'name', props.customAlarm, props.setCustomAlarm);
    };

    return (
        <div className="p-grid crud-demo">
            <ToastContainer />
            <div className="card">
                <Dialog visible={props.visible} style={{ width: "450px" }} header={props.dialogHeader} modal className="p-fluid" footer={customAlarmDialogFooter} onHide={hideDialog}>
                    <Toast ref={toast} />
                    {!props.customAlarm.id && <>
                        <span className="titulo-categoria">USUÁRIO:</span>
                        <div className="categoria">
                            <div className="categoria mb-2">
                                <strong>Nome completo do responsável pelo cadastro *</strong>
                                <InputText
                                    id="name"
                                    value={props.customAlarm.name}
                                    onChange={handleNameChange}
                                    required
                                    autoFocus
                                    className={classNames({ 'p-invalid': props.submitted && (!props.customAlarm.name || !isNameValid) })}
                                />
                            </div>
                        </div>
                    </>}
                    {!props.customAlarm.id && <>
                        <span className="titulo-categoria">CONFIGURAÇÃO CÂMERA:</span>
                        <div className="categoria">
                            <div className="formgrid grid">
                                <div className="p-field col-6">
                                    <strong>Localidade:</strong>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-map-marker"></i>
                                        </span>
                                        {
                                            <Dropdown
                                                emptyMessage="Nenhuma Localidade Encontrada"
                                                disabled={loadingLocations || props.isEditingCustomAlarm || !isNameValid}
                                                optionLabel="description"
                                                optionValue="id"
                                                options={locations}
                                                placeholder="Selecionar localidade"
                                                value={props.customAlarm.cameraLocation.id}
                                                onChange={(e) => onLocationChange(e, "cameraLocation")}
                                                className={classNames({ "p-invalid": props.submitted && props.customAlarm.cameraLocation.id === 0 })}
                                            />
                                        }
                                    </div>
                                    {props.submitted && props.customAlarm.cameraLocation.id === 0 && <small className="p-invalid">Selecione uma Localidade.</small>}
                                </div>
                                <div className="p-field col-6">
                                    <strong>Câmera:</strong>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-camera"></i>
                                        </span>
                                        {
                                            <Dropdown
                                                emptyMessage="Nenhuma Câmera Encontrada"
                                                disabled={(loadingCameras || props.customAlarm.cameraLocation.id === 0 || props.isEditingCustomAlarm || !isNameValid)}
                                                optionLabel="description"
                                                optionValue="id"
                                                options={cameras}
                                                placeholder="Selecionar câmera"
                                                value={props.customAlarm.camera.id}
                                                onChange={(e) => onSelectedCamera(e, 'camera')}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>}
                    <span className="titulo-categoria">CONFIGURAÇÃO ALARME:</span>
                    <div className="categoria">
                        <div className="formgrid grid">
                            <div className="p-field col-6">
                                <strong>Tipo:</strong>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-fw pi-chart-bar"></i>
                                    </span>
                                    {
                                        <Dropdown
                                            emptyMessage="Nenhum Tipo de Veículo Encontrado"
                                            disabled={(loadingLocations || loadingCameras || !isNameValid)}
                                            optionLabel="description"
                                            optionValue="id"
                                            options={getVehicleTypes()}
                                            placeholder="Selecionar tipo"
                                            value={props.customAlarm.vehicleTypeId}
                                            onChange={(e) => onVehicleDropdownValueChange(e, 'vehicleTypeId')}
                                            className={classNames({ "p-invalid": props.submitted && props.hasLessThanTwoParametersSelected() })}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="p-field col-6">
                                <strong>Marca:</strong>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-fw pi-chart-bar"></i>
                                    </span>
                                    {
                                        <Dropdown
                                            emptyMessage="Nenhuma Marca Encontrada"
                                            disabled={(loadingLocations || loadingCameras || !isNameValid)}
                                            optionLabel="description"
                                            optionValue="id"
                                            options={getVehicleBrands()}
                                            placeholder="Selecionar marca"
                                            value={props.customAlarm.vehicleBrandId}
                                            onChange={(e) => onVehicleDropdownValueChange(e, 'vehicleBrandId')}
                                            className={classNames({ "p-invalid": props.submitted && props.hasLessThanTwoParametersSelected() })}
                                        />
                                    }
                                </div>
                            </div>
                            <div className="p-field col-6">
                                <strong>Cor:</strong>
                                <div className="p-inputgroup">
                                    <span className="p-inputgroup-addon">
                                        <i className="pi pi-fw pi-chart-bar"></i>
                                    </span>
                                    {
                                        <Dropdown
                                            emptyMessage="Nenhuma Cor Encontrada"
                                            disabled={(loadingLocations || loadingCameras || !isNameValid)}
                                            optionLabel="description"
                                            optionValue="id"
                                            options={getVehicleColors()}
                                            placeholder="Selecionar cor"
                                            value={props.customAlarm.vehicleColorId}
                                            onChange={(e) => onVehicleDropdownValueChange(e, 'vehicleColorId')}
                                            className={classNames({ "p-invalid": props.submitted && props.hasLessThanTwoParametersSelected() })}
                                        />
                                    }
                                </div>
                            </div>
                            {props.customAlarm.vehicleTypeId == 1 &&
                                <div className="p-field col-6">
                                    <strong>Número de pessoas:</strong>
                                    <div className="p-inputgroup">
                                        <span className="p-inputgroup-addon">
                                            <i className="pi pi-fw pi-chart-bar"></i>
                                        </span>
                                        {
                                            <Dropdown
                                                emptyMessage="Nenhuma Opção Encontrada"
                                                optionLabel="description"
                                                optionValue="id"
                                                options={getNumberOfPassengers()}
                                                placeholder="Selecionar número de pessoas"
                                                value={props.customAlarm.numberOfPassengers}
                                                onChange={(e) => onVehicleDropdownValueChange(e, 'numberOfPassengers')}
                                                className={classNames({ "p-invalid": props.submitted && props.hasLessThanTwoParametersSelected() })}
                                            />
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        {props.submitted && props.hasLessThanTwoParametersSelected() &&
                            <small className="p-invalid">É necessário selecionar pelo menos dois parâmetros customizados.</small>}
                    </div>
                    {props.isEditingCustomAlarm &&
                        <div className="p-field-checkbox">
                            <Checkbox className="mr-2" inputId="isActive" name="option" checked={props.customAlarm.isActive} onChange={(e) => onCheckedChange(e, "isActive")} />
                            <span>Ativo</span>
                        </div>
                    }
                    {props.customAlarm.cameraLocation.id != 0 && (
                        <>
                            <span className="titulo-categoria">CONFIGURAÇÃO GRUPO WHATSAPP:</span>
                            <div className="categoria">
                                <WhatsappGroup selectedGroupId={props.customAlarm.whatsappGroupId} selectedLocation={props.customAlarm.cameraLocation.id} object={props.customAlarm} objectUpdate={props.setCustomAlarm} />
                            </div>
                        </>
                    )}
                </Dialog>
            </div>
        </div>
    )
}
