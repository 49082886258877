import axios from "axios";
import { environment } from "../configuration/environment";
import { AuthServices } from "./utils/auth-services";
import { PlateTypeEnum } from "../pages/Vehicle-Detection/types/types";
import { vehicleType } from "../shared/constants/vehicle-type";
import { vehicleColor } from "../shared/constants/vehicle-color";


export class VehicleDetectionServices {
    _authServices = new AuthServices();

    // Busca veículos por placa com coringa (*) e opcionalmente por tipo de placa
    searchByLicensePlateAndType(placa: string, plateType?: PlateTypeEnum, dataInicio?: Date, dataFim?: Date) {
        return axios.get(environment().baseUrl + environment().version + "/vehicle/search", {
            params: {
                placa: placa,
                plateType: plateType,
                dataInicio: dataInicio?.toISOString(),
                dataFim: dataFim?.toISOString(),
            },
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    // Busca o histórico de veículos
    getVehicleHistory(cameraId?: number, locationId?: number,  vehicleType?: string, vehicleColor?: string, selectedPlate?: string, dataInicio?: Date, dataFim?: Date) {
        return axios.get(environment().baseUrl + environment().version + "/vehicle/history", {
            params: {
                cameraId: cameraId,
                locationId: locationId,
                vehicleType: vehicleType,
                vehicleColor: vehicleColor,
                selectedPlate: selectedPlate,
                dataInicio: dataInicio?.toISOString(),
                dataFim: dataFim?.toISOString(),
            },
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    // Busca a rota de um veículo com base na placa
    getVehicleRoute(licensePlate: string) {
        return axios.get(environment().baseUrl + environment().version + "/vehicle/route", {
            params: {
                licensePlate: licensePlate,
            },
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    // Gera relatório de comportamento para um veículo
    getVehicleBehaviorReport(licensePlate: string) {
        return axios.get(environment().baseUrl + environment().version + "/vehicle/behavior-report", {
            params: {
                licensePlate: licensePlate,
            },
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    getCameras() {
        return axios.get(environment().baseUrl + environment().version + "/vehicle/cameras-data", {
            headers: { Authorization: this._authServices.GetUserToken() },
        });
    }

    getCamerasByLocationDataAnalysis(locationId: number) {
        return axios
            .get(environment().baseUrl + environment().version + '/vehicle/cameras-data-by-location/' + locationId, {
                headers: {
                    'Authorization': this._authServices.GetUserToken()
                }
            });
    }


}
