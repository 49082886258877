export const onInputChange = (e: any, property: string, object: any, updateObject: (object: any) => void): void => {
    const val = (e.target && e.target.value) || "";
    let _object = { ...object } as any;
    _object[`${property}`] = val;
    updateObject(_object);
}

export const onInputDateChange = (e: any, property: string, object: any, updateObject: (object: any) => void): void => {
    const value = e.value ? new Date(e.value.getTime() - (e.value.getTimezoneOffset() * 60000)) : null;

    let _object = { ...object } as any;
    _object[`${property}`] = value;
    updateObject(_object);
};


export const onTimeChange = (e: any, property: string, object: any, updateObject: (object: any) => void): void => {
    const val = (e.target && e.target.value) || "";

    let _object = { ...object } as any;

    console.log('Old Value',_object.startHour)
    _object[`${property}`] = val;
    updateObject(_object);
}

export const onCheckedChange = (e: any, property: string, object: any, updateObject: (object: any) => void): void => {
    const val: boolean = (e.target && e.target.checked) || false;
    let _object = { ...object } as any;
    _object[property] = val;

    updateObject(_object);
}

export const onNumberChange = (e: any, property: string, object: any, updateObject: (object: any) => void): void => {
    const val = (e && e.value) || "0";
    let _object = { ...object } as any;
    _object[property] = val;
    updateObject(_object);
}

export const translateColor = (color: string): string => {
    const colorMap: { [key: string]: string } = {
        "Red": "Vermelho",
        "Blue": "Azul",
        "Green": "Verde",
        "Black": "Preto",
        "White": "Branco",
        "Silver": "Prata",
        "Gray": "Cinza",
        "Yellow": "Amarelo",
        "Orange": "Laranja",
        "Brown": "Marrom",
        "Purple": "Roxo",
        "Pink": "Rosa",             // Cor adicionada
        "Darkorange": "Laranja Escuro", // Cor adicionada
        "Beige": "Bege",            // Cor adicionada
        // Adicione mais cores conforme necessário
    };

    // Retorna a tradução ou a cor original se não houver correspondência
    return colorMap[color] || color;
};

export const untranslateColor = (color: string): string => {
    const reverseColorMap: { [key: string]: string } = {
        "Vermelho": "Red",
        "Azul": "Blue",
        "Verde": "Green",
        "Preto": "Black",
        "Branco": "White",
        "Prata": "Silver",
        "Cinza": "Gray",
        "Amarelo": "Yellow",
        "Laranja": "Orange",
        "Marrom": "Brown",
        "Roxo": "Purple",
        "Rosa": "Pink",             // Cor adicionada
        "Laranja Escuro": "Darkorange", // Cor adicionada
        "Bege": "Beige",            // Cor adicionada
        // Adicione mais cores conforme necessário
    };

    // Retorna a cor original em inglês ou a cor original se não houver correspondência
    return reverseColorMap[color] || color;
};


// export const translateType = (type: string): string => {
//     const typeMap: { [key: string]: string } = {
//         "CARRO": "Carro",
//         "MOTO": "Moto",
//         "PICKUP": "Pickup",
//         "ÔNIBUS": "Ônibus",
//         "CAMINHÃO": "Caminhão",
//         "OUTRO": "Outro",
//         "DUMMY": "Dummy" // Ou o valor que quiser para "DUMMY"
//     };

//     // Retorna a tradução ou o tipo original se não houver correspondência
//     return typeMap[type] || type;
// };


