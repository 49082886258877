import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PageHeader } from '../../../views/page-header';

export const VehicleRoute = () => {
    const [licensePlate, setLicensePlate] = useState('');
    const [route, setRoute] = useState([]);

    const handleGetRoute = () => {
        axios.get(`/api/v2/vehicle/route?licensePlate=${licensePlate}`)
            .then(response => {
                setRoute(response.data);
            })
            .catch(error => {
                console.error('Erro ao buscar a rota do veículo', error);
            });
    };

    return (
        <React.Fragment>
            <div className="grid">
                <div className="col-12">
                    <PageHeader
                        title="ROTA DE VEÍCULOS"
                        icon="pi pi-car"
                    />
                    <div className="card">
                        <input
                            type="text"
                            value={licensePlate}
                            onChange={(e) => setLicensePlate(e.target.value)}
                            placeholder="Digite a placa"
                        />
                        <button onClick={handleGetRoute}>Buscar Rota</button>

                        <ul>
                            {route.map((point, index) => (
                                <li key={index}>Latitude: {point.latitude}, Longitude: {point.longitude}, Tempo: {point.detectionTime}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};
