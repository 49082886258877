export const environment = () => {
    const moonEnv = process.env.REACT_APP_moon_env;

    // Verifica se a variável `moon_env` existe e é igual a "local"
    const isLocal = moonEnv === "local";

    return {
        baseUrl: isLocal ? "https://localhost:44368/" : "https://moon-ai.azurewebsites.net/",
        signalR: isLocal ? "https://localhost:44368/" : "https://moon-ai.azurewebsites.net/",
        version: "v2"
    };
};
