import axios from "axios";
import { environment } from "../configuration/environment";
import { AuthServices } from "./utils/auth-services";

export class LocationServices {
    _authServices = new AuthServices();
    getActiveLocations(date: Date) {
        return axios
            .get(environment().baseUrl + environment().version + '/location/active-locations', {
                params: {
                    date: date.toLocaleString("en-us", { day: '2-digit', month: '2-digit', year: 'numeric' })
                },
                headers: {
                    'Authorization': this._authServices.GetUserToken()
                }
            });
    }

    getActiveLocationsDataAnalysis() {
        return axios
            .get(environment().baseUrl + environment().version + '/location/active-locations-data-analysis', {
                headers: {
                    'Authorization': this._authServices.GetUserToken()
                }
            });
    }

    

    getLocationsWithActiveCameras() {
        return axios.get(environment().baseUrl + environment().version + '/location/locations-with-cameras', {
            headers: {
                'Authorization': this._authServices.GetUserToken()
            }
        });
    }

    getLocationsByUf(uf: string) {
        return axios.get(environment().baseUrl + environment().version + "/location/by-uf/" + uf, {
            headers: {
                'Authorization': this._authServices.GetUserToken()
            },
        });
    }

    getActiveMapLocations() {
        return axios
            .get(environment().baseUrl + environment().version + '/location-feature/locations-by-feature', {
                headers: {
                    'Authorization': this._authServices.GetUserToken()
                }
            });
    }

    getLocationMapById(id: number) {
        return axios.get(environment().baseUrl + environment().version + "/location/map/" + id, {
            headers: {
                'Authorization': this._authServices.GetUserToken()
            },
        });
    }
}
