export interface blackListItem {
    idBlackList: number,
    name: string,
    plate: string,
    reason: string,
    description: string,
    locationId: number,
    locationName: string,
    active: boolean,
    whatsappGroupId: number
}

export const blankBlackList: blackListItem = {
    idBlackList: 0,
    name: ``,
    plate: ``,
    reason: ``,
    description: ``,
    locationId: 0,
    locationName: ``,
    active: true,
    whatsappGroupId: null
}

export const eventReasons: string[] = ['OUTRA', 'VEICULO ROUBADO', 'VEICULO SUSPEITO']
