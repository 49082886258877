import { DropdownInfo } from "../../../models/types";

export const emptyDropdown: DropdownInfo = {
    id: 0,
    description: "",
};

export const emptyCustomAlarm: customAlarmInterface = {
    id: 0,
    camera: emptyDropdown,
    cameraLocation: emptyDropdown,
    name: "",
    vehicleTypeId: 6,
    vehicleColorId: 0,
    vehicleBrandId: 0,
    isActive: false,
    userId: 0,
    creationDateTime: new Date(),
    numberOfPassengers: 0,
    whatsappGroupId: null,
};

export interface customAlarmInterface {
    id: number;
    camera: DropdownInfo;
    cameraLocation: DropdownInfo;
    name: string;
    vehicleTypeId: number;
    vehicleColorId: number;
    vehicleBrandId: number;
    isActive: boolean;
    userId: number;
    creationDateTime: Date;
    numberOfPassengers: number;
    whatsappGroupId: number;
}

export interface customAlarmRequest {
    cameraId: number;
    vehicleType: number;
    vehicleColor: number;
    vehicleBrand: number;
    numberOfPassengers: number;
}

export interface customAlarmUpdateRequest {
    cameraId: number;
    vehicleType: number;
    vehicleColor: number;
    vehicleBrand: number;
    isActive: boolean;
    numberOfPassengers: number;
    whatsappGroupId: number;
}

export interface eventsRequest {
    cameraId: number;
    locationId: number;
    vehicleType: number;
    vehicleColor: number;
    vehicleBrand: number;
    numberOfPassengers: number;
}
