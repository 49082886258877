export interface LoginInterface {
    username: string,
    password: string,
    rememberPassword: boolean
}

export interface user {
    id: number;
    userName: string;
    userAccess: string;
    email: string;
    isAdministrator?: boolean;
    isMultifactor: boolean;
    isTemporary?: boolean;
    isRestrictedLocal?: boolean;
    isRestrictedTime?: boolean;
    expiration?: Date;
    initialTime?: Date;
    endTime?: Date;
    ipAddressRestricted?: string;
    password?: string;
    newpassword?: string;
    repetpassword?: string;
    allowedLocations?: allowedLocations[];
}

export interface allowedLocations {
    locationId: number;
    locationName: string;
}

export const emptyUser = {
    isAdministrator: false,
    isMultifactor: false,
    isTemporary: false,
    isRestrictedLocal: false,
    isRestrictedTime: false,
    expiration: null,
    initialTime: null,
    endTime: null,
    ipAddressRestricted: null,
    allowedLocations: []
} as user;

export interface allowedLocations {
    locationId: number;
    locationName: string;
}

export interface LoginResponse {
    accessToken: string,
    userToken: LoginUserToken
}

export interface LoginUserToken {
    claims: Claims[]
}

export interface Claims {
    value: string,
    type: string
}

export const emptyLocation = {
    locationId: 0,
    locationName: ""
} as allowedLocations;
