import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { PageHeader } from '../../../views/page-header';

export const BehaviorReport = () => {
    const [licensePlate, setLicensePlate] = useState('');
    const [report, setReport] = useState([]);

    const handleGetReport = () => {
        axios.get(`/api/v2/vehicle/behavior-report?licensePlate=${licensePlate}`)
            .then(response => {
                setReport(response.data);
            })
            .catch(error => {
                console.error('Erro ao gerar o relatório de comportamento', error);
            });
    };

    return (
        <React.Fragment>
            <div className="grid">
                <div className="col-12">
                    <PageHeader
                        title="RELATÓRIO DE COMPORTAMENTO"
                        icon="pi pi-chart-bar"
                    />
                    <input
                        type="text"
                        value={licensePlate}
                        onChange={(e) => setLicensePlate(e.target.value)}
                        placeholder="Digite a placa"
                    />
                    <button onClick={handleGetReport}>Gerar Relatório</button>

                    <ul>
                        {report.map((item, index) => (
                            <li key={index}>{item.cameraId} - {item.hour}h - {item.dayOfWeek} - {item.count} vezes</li>
                        ))}
                    </ul>
                </div>
            </div>
        </React.Fragment>
    );
};
