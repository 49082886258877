import { environment } from "../configuration/environment";
import axios from "axios";
import { dsmApplication } from "../pages/DSM-Application/types/types";
import { AuthServices } from "./utils/auth-services";

export class DSMApplicationServices {
    _authServices = new AuthServices();

    getDSMApplications() {
        return axios.get(environment().baseUrl + environment().version + "/dsm-application", {
            headers: { Authorization: sessionStorage.getItem("Bearer") },
        });
    }

    addDSMApplication(dsmApplication: dsmApplication) {
        return axios.post(environment().baseUrl + environment().version + "/dsm-application", dsmApplication, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    updateDSMApplication(dsmApplication: dsmApplication) {
        return axios.put(environment().baseUrl + environment().version + "/dsm-application/" + dsmApplication.dsmApplicationId, dsmApplication, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    deleteDSMApplication(dsmApplicationId: number) {
        return axios.delete(environment().baseUrl + environment().version + "/dsm-application/" + dsmApplicationId, {
            headers: {
                Authorization: this._authServices.GetUserToken(),
            },
        });
    }

    getCitiesByState(state: string) {
        return axios.get(environment().baseUrl + environment().version + "/location/by-uf/" + state, {
            headers: {
                Authorization: this._authServices.GetUserToken()
            },
        });
    }

    getCityById(id: number) {
        return axios.get(environment().baseUrl + environment().version + "/location/" + id, {
            headers: {
                Authorization: this._authServices.GetUserToken()
            },
        });
    }

    getStateByApplication(dsmApplicationId: number) {
        return axios.get(environment().baseUrl + environment().version + "/dsm-application/uf-by-application/" + dsmApplicationId, {
            headers: {
                Authorization: this._authServices.GetUserToken()
            },
        });
    }
}
